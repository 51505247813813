import axios from 'axios';
import Logger from 'js-logger';

const logger = Logger.get('AgentApis');
const { REACT_APP_PORTAL_URL } = process.env;
const portalAxios = axios.create({
    baseURL: REACT_APP_PORTAL_URL,
});

/**
 * Gets an agents info
 * @param {String} agentId 
 */
export async function getAgentsInfo(agentId) {
    if(!agentId) throw new Error('agentId is required');

    const URL = `/v1/affiliatecodes/${agentId}`;

    try {
        const response = await portalAxios.get(URL);
        return response.data;
    } catch(err) {
        logger.error(err);
        throw err;
    }
}
