import { useCallback, useRef, useEffect } from 'react';
import Logger from 'js-logger';

const logger = Logger.get('StillHereTimer');


export function useStillHereTimer(cb, timeoutMs, { lazy = false } = {}) {
    const cbRef = useRef(cb);
    let timeoutRef = useRef(null);

    const start = useCallback(function () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        logger.debug(`Start timer with ${timeoutMs / 1000} seconds`);
        timeoutRef.current = setTimeout(() => {
            logger.debug(`Execute timer callback`);
            cbRef.current();
            timeoutRef.current = null;
        }, timeoutMs);
    }, [timeoutMs]);

    const stop = useCallback(function () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        cbRef.current = cb;
    }, [cb])

    useEffect(() => {
        if (lazy) {
            // If lazy don't run immediately
            return;
        }
        start();
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, [lazy, start])

    return {
        stop,
        start,
        restart: start,
    }
}