// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { LRAvatar } from '../../../LRAvatar';
import { startCase } from 'lodash-es';

export function Comment({ 
    message, 
    previousMessage,
    nextMessage,
    ...props 
}) {
    const previousMessageOwnedBySameUser = previousMessage && previousMessage?.userId === message.userId;
    const shouldShowTime = useMemo(()=> {
        if(nextMessage?.createdAt) {
            const dateAndTimeCurrent = dayjs(message.createdAt).format('YYYY-MM-DD-HH:mm');
            const dateAndTimeNext = dayjs(nextMessage.createdAt).format('YYYY-MM-DD-HH:mm');

            return !(dateAndTimeCurrent === dateAndTimeNext && message.userId === nextMessage.userId);
        }

        return true;
    }, [nextMessage?.createdAt]);

    return (
        <Box mt={!previousMessageOwnedBySameUser && 2} px={2} textAlign={ message?.isLocalUser && 'right' } {...props}>
            {!previousMessageOwnedBySameUser && (
                <Box mb={1}>
                    <Grid 
                        container 
                        wrap="nowrap" 
                        justify={message.isLocalUser ? 'flex-end' : 'flex-start'} 
                        alignItems="center"
                    >
                        <Box order={message.isLocalUser && 2} mr={!message.isLocalUser && 1} ml={message.isLocalUser && 2}>
                            <LRAvatar name={message.userName?.toUpperCase()} size="small"></LRAvatar>
                        </Box>

                        <Box order={message.isLocalUser && 1}>
                            <Grid container alignItems="flex-end">
                                <Typography variant="body1">
                                    {startCase(message.userName)}
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            )}

            <Box display="inline-block" mt={.5}>
                <Box
                    bgcolor={message.isLocalUser ? '#fff' : '#333'}
                    color={!message.isLocalUser && '#fff'}
                    borderRadius="4px"
                    py={1}
                    px={2}
                    style={{
                        [message.isLocalUser ? 'borderTopRightRadius' : 'borderTopLeftRadius']: 0
                    }}
                >
                    <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                        {message.content.message}
                    </Typography>
                </Box>

                {shouldShowTime && (
                    <Box mt={.25}>
                        <Typography
                            style={{
                                fontSize: '0.625rem',
                                color: '#999'
                            }}
                        >
                            {dayjs(message.createdAt)
                                .format('h:mm a')}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
