

export function initialize(user) {
    if(!pendo) return;
    pendo.initialize({
        visitor: {
            id: user.id,
        },
        account: {}
    });
}