import { createMuiTheme } from '@material-ui/core/styles';
import { COLOR_PRIMARY_LIGHT, COLOR_PRIMARY, COLOR_PRIMARY_DARK, COLOR_SECONDARY_LIGHT, COLOR_SECONDARY, COLOR_SECONDARY_DARK, COLOR_RED, COLOR_GREEN, COLOR_BLUE } from '../constants';
import { omit } from 'lodash-es';

const defaultMuiTheme = createMuiTheme({});
const { breakpoints } = defaultMuiTheme;

// COLORS
const PRIMARY_COLOR = {
    light: COLOR_PRIMARY_LIGHT,
    main: COLOR_PRIMARY,
    dark: COLOR_PRIMARY_DARK,
    contrastText: '#fff',
};
const SECONDARY_COLOR = {
    light: COLOR_SECONDARY_LIGHT,
    main: COLOR_SECONDARY,
    dark: COLOR_SECONDARY_DARK,
    contrastText: '#fff',
};
const ERROR_COLOR = {
    main: COLOR_RED,
};
const DEFAULT_THEME = {
    typography: {
        fontFamily: 'proxima-soft, Helvetica, Arial, sans-serif',
        fontSize: 14,
        htmlFontSize: 14,
        useNextVariants: true,
        h1: {
            fontSize: '3rem',
            lineHeight: 1,
            fontWeight: 'bold',
            [breakpoints.down('xs')]: {
                fontSize: '2.125rem',
            },
        },
        h2: {
            fontSize: '1.75rem',
            lineHeight: 1.2,
            fontWeight: 'bold',
            [breakpoints.down('xs')]: {
                fontSize: '1.5rem',
            },
        },
        h3: {
            fontSize: '1.125rem',
            lineHeight: 1.3,
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '14px',
        },
        body2: {
            fontSize: '16px',
        },
        body3: {
            fontSize: '10px',
        },
        subtitle1: {
            fontSize: '10px',
        }
    },
    palette: {
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
        error: ERROR_COLOR,
        // text: {
        //     primary: COLOR_GIGGITY,
        // },
    },
    // overrides: {
    //     MuiIconButton: {
    //         root: {
    //             color: '#000',
    //         },
    //     },
    // },
};

// THEME
export const defaultTheme = createMuiTheme(DEFAULT_THEME);

// OTHER THEMES

// example
// export const whiteTheme = createMuiTheme({
//     ...DEFAULT_THEME,
//     palette: { ...omit(DEFAULT_THEME.palette, 'primary', 'secondary'), primary: DEFAULT_THEME.palette.white },
// });

export const callButtonTheme = createMuiTheme({
    ...DEFAULT_THEME,
    palette: {
        ...omit(DEFAULT_THEME.palette, 'primary', 'secondary'),
        primary: defaultTheme.palette.augmentColor({
            main: COLOR_GREEN,
            contrastText: '#fff',
        }),
        secondary: defaultTheme.palette.augmentColor({
            main: COLOR_RED,
            contrastText: '#fff',
        }),
    }
});

export const recordButtonTheme = createMuiTheme({
    ...DEFAULT_THEME,
    palette: {
        ...omit(DEFAULT_THEME.palette, 'primary', 'secondary'),
        primary: defaultTheme.palette.augmentColor({
            main: COLOR_SECONDARY,
            contrastText: '#fff',
        }),
        secondary: defaultTheme.palette.augmentColor({
            main: COLOR_RED,
            contrastText: '#fff',
        }),
    }
});

export const agentBGTheme = createMuiTheme({
    ...DEFAULT_THEME,
    typography: {
        ...omit(DEFAULT_THEME.typography, 'h1', 'h2', 'h3'),
        h1: {
            fontSize: '2.5rem',
            lineHeight: 1.2,
            fontWeight: 'normal',
            [breakpoints.down('xs')]: {
                fontSize: '1.25rem',
            },
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: 1,
            fontWeight: '500',
            [breakpoints.down('xs')]: {
                fontSize: '1rem',
            },
        },
        h3: {
            fontSize: '1.125rem',
            lineHeight: 1.3,
            fontWeight: 'normal',
            [breakpoints.down('xs')]: {
                fontSize: '0.875rem',
            },
        },
    },
    palette: {
        ...omit(DEFAULT_THEME.palette, 'primary'),
        primary: defaultTheme.palette.augmentColor({
            main: COLOR_BLUE,
            contrastText: '#fff',
        }),
    }
});
