import React from 'react';
import { 
    Call as CallIcon, 
    CallEnd as CallEndIcon,
    PhoneDisabled as PhoneDisabledIcon,
} from '@material-ui/icons';
import { callButtonTheme } from '../../../theme';
import { MuiThemeProvider } from '@material-ui/core';
import { ToolbarButton } from '../ToolbarButton';
import Logger from 'js-logger';

const logger = Logger.get('CallButton');

export function CallButton({
    isOnCall = false,
    disabled = false,
    onStartCallClick = ()=> {},
    onEndCallClick = ()=> {},
    ...props
}) {
    logger.debug('isOnCall', isOnCall);

    return (
        <MuiThemeProvider theme={callButtonTheme}>
            <ToolbarButton 
                {...props} 
                disabled={disabled} 
                color={isOnCall ? 'secondary' : 'primary'} 
                onClick={isOnCall ? onEndCallClick : onStartCallClick}
            >
                {!isOnCall && !disabled && <CallIcon />}
                {isOnCall && !disabled && <CallEndIcon />}
                {disabled && <PhoneDisabledIcon />}
            </ToolbarButton>
        </MuiThemeProvider>
    );
}
