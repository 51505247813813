import React from 'react';
import GenericPage from '../../components/GenericPageCenteredText';

export function Four0FourPage() {
    return (
        <GenericPage
            variant='h4'
            color='error'
            text='Meeting not found'
        />
    );
}
