import React, { useEffect, useRef } from 'react';
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        // height: '90vh',
        overflow: 'auto',
    },
}));

export default function MessageList(props) {

    const { messageHistory } = props;
    const classes = useStyles();

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messageHistory]);


    return (
        <List height="100%" className={classes.list}>
            {messageHistory.map((payload, i) => {
                let component
                if(payload.e === 'message') {
                    component = <Message {...payload.p} />
                } else if(['joined', 'left', 'participant_updated'].includes(payload.e)) {
                    component = <InfoMessage {...payload} />
                } else {
                    return '';
                }
                return (
                    <React.Fragment key={i}>
                        {component}
                        {(i !== messageHistory.length - 1)
                            ? <Divider component="li" />
                            : ''
                        }
                    </React.Fragment>
                )
            })}
            <div ref={messagesEndRef} />
        </List>
    );
}


function Message(props) {
    const { userName, createdAt, content } = props;
    return (
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            component="span"
                            variant="body1"
                            color="secondary"
                        >
                            {userName}
                        </Typography>
                        <Typography
                            component="span"
                            variant="caption"
                            color="textPrimary"
                        >
                            {dayjs(createdAt).format('MMM DD, YYYY h:mm:ss A')}
                        </Typography>
                    </Box>
                }
                secondary={
                    <React.Fragment>
                        {/* <Typography
                                            component="span"
                                            variant="subtitle1"
                                            color="textPrimary"
                                        >
                                            {createdAt}
                                        </Typography> */}
                        <Typography
                            component="span"
                            variant="body1"
                            color="textPrimary"
                        >
                            {content}
                        </Typography>
                    </React.Fragment>
                }
            />
        </ListItem>
    )
}


function InfoMessage(props) {
    const { e, p } = props;

    let text = null;
    switch(e) {
        case 'joined':
        case 'left':
            text = `${p.userName} ${e} the room`;
            break;
        case 'participant_updated':
            text = `${p.oldUserName} changed their name to ${p.userName}`;
            break;
        default:
            break;
    }
    if(!text) return '';

    return (
        <ListItem alignItems="center">
            <ListItemText
                secondary={
                    <Typography
                        align="center"
                        component="div"
                        variant="body1"
                        color="secondary"
                    >
                        {text}
                    </Typography>
                }
            >
            </ListItemText>
        </ListItem>
    )
}

