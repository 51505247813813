import React from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';
import { Room } from './scenes/Room';
import { RoomRemote } from './scenes/RoomRemote';
import { HomePage } from './scenes/Home';
import { Four0FourPage } from './scenes/404Page';
import { ThankYouPage }from './scenes/ThankYouPage';
import { defaultTheme } from './theme';
import { StoreProvider, rootStore } from './mobx-store';
import {
    BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';

const GlobalStyles = createGlobalStyle`
    html {
        height: 100%;
        width: 100%;
    }

    body {
        // background: url(https://www.visualatin.agency/wp-content/uploads/2015/01/blurred-background-10-2000x12501.jpg) no-repeat center center !important;
        background-size: cover !important;margin: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #root {
        width: 100%;
        height: 100%;
        overflow-y: scroll
    }
`;

function App() {
    return (
        <>
            <CssBaseline />
            <GlobalStyles />

            <MuiThemeProvider theme={defaultTheme}>
                <StoreProvider value={rootStore}>
                    <Router>
                        <Switch>
                            <Route path="/404" component={Four0FourPage} />
                            <Route path="/close" component={ThankYouPage} />
                            <Route path="/remote/:roomId">
                                <RoomRemote />
                            </Route>
                            <Route path="/:roomId">
                                <Room />
                            </Route>
                            <Route path="/" component={HomePage} />
                            <Route component={Four0FourPage} />
                        </Switch>
                    </Router>
                </StoreProvider>
            </MuiThemeProvider>
        </>
    );
}

export default App;
