import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom';
import Logger from 'js-logger';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReadyState } from 'react-use-websocket';
import useLocalStorage from 'react-use-localstorage';
import { Box, Typography } from '@material-ui/core';
import { random } from 'lodash-es';

import UserName from './UserName';
import NewMessage from './NewMessage';
import MessageList from './MessageList';
import { REALTIME_JOINED, REALTIME_LEFT, REALTIME_HISTORY, REALTIME_PARTICIPANT_UPDATED, REALTIME_CONNECTION_INFO, REALTIME_MESSAGE, REALTIME_ACTION_HISTORY, REALTIME_ACTION_CONNECTION_INFO } from '../../constants';
import { useRealtime } from '../../hooks/useRealtime';

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    list: {
        overflow: 'auto',
    },
    form: {
        // height: '10vh',
    },
    newMessageField: {
        width: '90%',
    },
    container: {
        // height: '76vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    connectionStatus: {
        padding: '0 5px',
    }
}));

const defaultUsername = `Guest${random(100, 999)}`;

export default function Chat(props) {

    const { height, userName: propUserName = defaultUsername } = props;
    const { meetingId } = useParams();
    const [userName, setUserName] = useLocalStorage('chat-username', propUserName);
    const [room,] = useState(props.room || meetingId);
    const [userId,] = useState('LiveFoo');
    const classes = useStyles();
    
    const {
        messageHistory,
        hasFetchedHistory,
        connectionStatus,
        sendMessage,
    } = useChat(userName, room, userId);

    useEffect(()=> {
        Logger.debug('saving username to local storage');
        setUserName(userName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box display="flex" flexGrow={1} flexDirection="column" height={height}>
            {!hasFetchedHistory
                ? <Box display="flex"
                    flexGrow={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
                : (messageHistory.length)
                    ? <MessageList messageHistory={messageHistory} />
                    : <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="90vh"
                    >
                        <Typography variant="title">No messages yet, start a conversation!</Typography>
                    </Box>
            }
            <NewMessage sendMessage={sendMessage} connectionStatus={connectionStatus} />
            <UserName
                sendMessage={sendMessage}
                connectionStatus={connectionStatus}
                localUserName={userName}
                setLocalUserName={setUserName}
            />
        </Box>
    );
}



export function useChat(userName, room, userId) {

    const readyStateOpen = useRef(false);
    const realtimeOptions = useMemo(()=> ({
        userName,
        userId,
        room,
    }), [userName, userId, room]);
    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useRealtime(realtimeOptions);
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
    }[readyState];

    // upon the readyState changing, if connected, ask for connectionInfo
    useEffect(() => {
        Logger.debug('Socket readyState', readyState);
        if (readyState === ReadyState.OPEN) {
            readyStateOpen.current = true;
            // ask for the connectionInfo every time
            sendMessage({ action: REALTIME_ACTION_CONNECTION_INFO });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyState]);


    // set up event handlers for new messages from the websocket
    const {
        hasFetchedHistory,
        messageHistory,
    } = useMessageHandlers(lastMessage, sendMessage);

    return {
        messageHistory,
        hasFetchedHistory,
        connectionStatus,
        sendMessage,
    };
}


function useMessageHandlers(lastMessage, sendMessage) {
    const [connectionId, setConnectionId] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [hasFetchedHistory, setHasFetchedHistory] = useState(false);
    const [messageHistory, setMessageHistory] = useState([]);

    // on getting connectionId for the first time, get history
    useEffect(() => {
        Logger.debug('on Connection Info');
        if (connectionId !== null && !hasFetchedHistory) {
            // add your join event as part of the messages
            setMessageHistory(prev => prev.concat({ e: 'joined', p: { userName: 'You' } }));
            sendMessage({ action: REALTIME_ACTION_HISTORY });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionId, hasFetchedHistory]);

    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const { data } = lastMessage;
                const { e, p } = JSON.parse(data);
                switch (e) {
                    case REALTIME_CONNECTION_INFO:
                        const { connectionId: cId } = p;
                        setConnectionId(cId);
                        break;

                    case REALTIME_MESSAGE:
                        setMessageHistory(prev => prev.concat({ e, p }));
                        break;

                    case REALTIME_PARTICIPANT_UPDATED:
                        const indexToReplace = participants.findIndex(({ connectionId }) =>
                            connectionId === p.connectionId
                        );
                        if (indexToReplace > -1) {
                            participants[indexToReplace] = p;
                        } else {
                            participants.push(p)
                        }
                        Logger.debug('participant_updated', p);
                        setParticipants(participants);
                        setMessageHistory(prev => prev.concat({ e, p }));
                        break;

                    case REALTIME_JOINED:
                    case REALTIME_LEFT:
                        setMessageHistory(prev => prev.concat({ e, p }));
                        break;

                    case REALTIME_HISTORY:
                        if(hasFetchedHistory) break;

                        const { messages, connections } = p;
                        setParticipants(connections);
                        const participantJoinedMessages = connections
                            // filter out your own connection when displaying joined
                            .filter(({ connectionId: cId }) =>
                                cId !== connectionId
                            )
                            .map(({ userName }) => ({
                                e: 'joined',
                                p: { userName },
                            }));
                        setMessageHistory(prev => prev.concat(...participantJoinedMessages));
                        setMessageHistory(prev => prev.concat(...messages));
                        setHasFetchedHistory(true);
                        break;

                    default:
                        break;
                }
            } catch (err) {
                Logger.error('Error parsing message', err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    return {
        messageHistory,
        hasFetchedHistory,
    };
}
