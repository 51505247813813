import { useEffect, useMemo, useState } from "react";
import Logger from "js-logger";
import { random, omit } from "lodash-es";
import { REALTIME_JOINED, REALTIME_PARTICIPANT_UPDATED, REALTIME_LEFT, REALTIME_HISTORY, REALTIME_ACTION_HISTORY } from "../../constants";
import { useRealtime } from "../useRealtime";
import { ReadyState } from "react-use-websocket";

const logger = Logger.get('useParticipantsName');

export function useParticipantsName({
    userName,
    userId,
    room,
}) {
    const [names, setNames] = useState({});
    const realtimeOptions = useMemo(()=> ({
        userName,
        userId,
        room,
        onOpen: onConnectionOpen
    }), [userName, userId, room]);
    const {
        sendMessage,
        lastMessage,
    } = useRealtime(realtimeOptions);

    /**
     * Handles all the incoming messages from the 
     * realtime service.
     */
    useEffect(()=> {
        logger.debug('[lastMessage]', lastMessage);

        if(!lastMessage) return;

        const { data } = lastMessage;
        const { e, p } = JSON.parse(data);

        logger.debug('[lastMessage] e', e);
        logger.debug('[lastMessage] p', p);

        switch(e) {
            case REALTIME_JOINED:
            case REALTIME_PARTICIPANT_UPDATED:
                setNames({
                    ...names,
                    [p.userId]: p.userName,
                });
                break;
            case REALTIME_LEFT:
                setNames(omit(names, [p.userId]));
                break;
            case REALTIME_HISTORY:
                const { connections } = p;
                updateNamesFromHistory(connections);
                break;
            default:
                break;
        }
    }, [lastMessage]);

    /**
     * Once we are connected send a message to get the
     * connection history for the names.
     */
    function onConnectionOpen() {
        sendMessage({ action: REALTIME_ACTION_HISTORY });
    }

    /**
     * When we receive the history of participants currently
     * connected we need to update the participants name
     * mappings so the app can display their name.
     * @param {Array} connections 
     */
    function updateNamesFromHistory(connections) {
        const participantsNames = connections.reduce((result, c)=> {
            result[c.userId] = c?.userName || `Guest${random(100, 999)}`;
            return result;
        }, 
        {
            'local': userName
        })

        setNames(participantsNames);
    }

    /**
     * This will return a users actual name if it has
     * been registered. If not it will default to a
     * Guest Name.
     * @param {String} sessionId 
     */
    function getParticipantsName(sessionId) {
        return names[sessionId];
    }

    return {
        getParticipantsName
    };
}
