import { createContext, useContext } from 'react';
import { RoomStore } from './RoomStore';
import { MessengerStore } from './MessengerStore';

/**
 * RootStore class
 */
export class RootStore {
    constructor() {
        this.RoomStore = new RoomStore(this);
        this.MessengerStore = new MessengerStore(this);
    }
}

/**
 * Our main store for the app.
 */
export const rootStore = new RootStore();

/**
 * Used to store the store so we can use it
 * in a hook.
 */
export const StoreContext = createContext();
export const StoreProvider = StoreContext.Provider;

/**
 * This is our main store you will use for functional
 * components.
 */
export function useStore() {
    return useContext(StoreContext);
}
