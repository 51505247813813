import React from 'react';
import styled from 'styled-components';
import { Select, InputBase } from '@material-ui/core';

const StyledSelect = styled(Select)`
    & .MuiSelect-icon {
        color: #000;
    }
`;
const StyledInputBase = styled(InputBase)`
    background: transparent;
    border: 0;
    font-size: 0.875rem;

    & select {
        position: relative;
        border: 1px solid #bbb;
        border-radius: 4px;
        background-color: #fff;
        padding: 10px 40px 10px 12px;

        &:focus {
            border-radius: 4px;
            border: 1px solid #bbb;
            background-color: #fff;
        }
    }

    & .MuiSelect-icon {
        right: 0;
    }
`;

function LRSelect(props) {
    const { children, native = true, ...otherProps } = props;

    return (
        <StyledSelect native={native} input={<StyledInputBase />} {...otherProps}>
            {children}
        </StyledSelect>
    );
}

export default LRSelect;
