import React from 'react';
import { BaseModal as _BaseModal } from '../../../components/Modals';
import { Box, Typography, DialogTitle, withTheme } from '@material-ui/core';
import PoweredByPng from '../../../images/powered-by-listreports.png';
import { CircleHeadshot } from '../../../components/CircleHeadshot';
import styled from 'styled-components';

const BaseModal = withTheme(styled(_BaseModal)`
    ${({ theme })=> theme.breakpoints.up('sm')} {
        .MuiDialog-container {
            align-items: center;
        }
    }
`);

export function AtCapacityModal({
    agent,
    open = false,
    ...props
}) {
    return (
            <BaseModal 
                open={open} 
                {...props}

                header={
                    <DialogTitle disableTypography style={{ padding: 0 }}>
                        {/* Headshot */}
                        <Box mb={2}>
                            <CircleHeadshot src={agent?.headshot} width="93px" mx="auto" />
                        </Box>

                        <Typography variant="h2" align="center" style={{ fontWeight: 'normal' }}>
                            Welcome to <b>{agent?.name}'s</b> virtual conference room!
                        </Typography>
                    </DialogTitle>
                }
            >
                <>
                    <Box mb={2} mt={3}>
                        <Box mb={1} textAlign="center">
                            <label>
                                <Typography variant="body2">
                                    Sorry, This conference room is currently at capacity.
                                </Typography>
                            </label>
                        </Box>
                    </Box>

                    <Box textAlign="center" position="absolute" bottom="0" right="0" mr={1} mb={.5}>
                        <img src={PoweredByPng} alt="Powered by ListReports" style={{ width: '95px' }} />
                    </Box>
                </>
            </BaseModal>
    );
}
