import React from 'react';
import styled from 'styled-components';
import { Dialog, Slide, Box, DialogContent, DialogActions, useMediaQuery } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import LRButton from '../../LRButton';
import Logger from 'js-logger';
import { Close } from '@material-ui/icons';

const logger = Logger.get('BaseModal');
const DefaultTransition = React.forwardRef(function DefaultTransition({ direction = 'down', ...props }, ref) {
    return <Slide direction={direction} ref={ref} {...props} />;
});
const BaseDialog = withTheme(styled(Dialog)`
    .MuiPaper-root {
        border-radius: 4px;
        ${({ theme })=> theme.breakpoints.only('xs')} {
            margin: 15px;
        }
    }

    .MuiDialog-container {
        align-items: flex-start;
    }
`);

/**
 * BaseModal
 *
 * Example usage:
 * <BaseModal
 *     {...props}
 *     header={
 *         <DialogTitle disableTypography>
 *             <Typography variant="h2" align="center">
 *                 {title}
 *             </Typography>
 *         </DialogTitle>
 *     }
 *     buttons={
 *         <>
 *             <LRButton variant="contained" color="primary">
 *                 ok
 *             </LRButton>
 *         </>
 *     }
 * >
 *     <Box textAlign="center">{children}</Box>
 * </BaseModal>
 */
export function BaseModal({
    children,
    buttons,
    header,
    hideCloseButton = false,
    TransitionComponent = DefaultTransition,
    onClose = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    let modalPadding = props.p || (isXs ? '24px 23px' : '32px 56px');
    return (
        <BaseDialog
            {...props}
            style={isXs && props.fullScreen ? { height: 'calc(100% - 30px)' } : {}}
            TransitionComponent={TransitionComponent}
        >
            <Box p={modalPadding} position="relative" height={isXs && props.fullScreen ? '100%' : 'auto'}>
                {!hideCloseButton && (
                    <Box position="absolute" top="15px" right="15px">
                        <LRButton variant="text" onClick={onClose} style={{ textDecoration: 'none' }}>
                            <Close />
                        </LRButton>
                    </Box>
                )}

                {header && <Box>{header}</Box>}

                {children && (
                    <DialogContent
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            height: isXs && props.fullScreen && !header && !buttons ? '100%' : 'auto',
                        }}
                    >
                        {children}
                    </DialogContent>
                )}

                {buttons && (
                    <Box clone justifyContent="center !important">
                        <DialogActions>{buttons}</DialogActions>
                    </Box>
                )}
            </Box>
        </BaseDialog>
    );
}
