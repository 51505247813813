import { invert } from 'lodash-es';

//////////////////////////////////////////////////
// DO NOT REORDER LIST OR OR CHANGE THE OFFSET
// BECAUSE DECODING WILL NOT WORK
//////////////////////////////////////////////////
const KEYS = [
    'O','5','-','k','C','R','f','y','w','i',
    'x','I','F','L','6','t','b','3','E','m',
    'n','v','H','G','9','d','j','S','B','Q',
    'X','h','o','K','0','8','A','N','e','W',
    'z','V','g','M','l','p','D','U','2','P',
    'c','s','T','Y','r','q','Z','7','a','u',
    '4','1','J'
];
const KEY_OFFSET = 33;
const LETTER_MAP = KEYS.reduce(function (obj, key, i) {
    obj[key] = String(KEYS[(i + KEY_OFFSET) % KEYS.length]);

    return obj;
}, {});
const REVERSE_LETTER_MAP = invert(LETTER_MAP);
const SOURCES_MAP = {
    LR: 'listreports',
    LH: 'listhub',
    WN: 'wolfnet',
    RT: 'rets',
    TR: 'trawler',
    FA: 'firstam',
    TN: 'tns',
    DL: 'dls'
};
const REVERSE_SOURCES_MAP = invert(SOURCES_MAP);

export function encode(source, str) {
    const prefix = REVERSE_SOURCES_MAP[source];
    const id = str
        .split('')
        .map(function encodeMap(char) {
            return LETTER_MAP[char] || char;
        })
        .join('');

    return prefix + id;
}

export function decode(str) {
    const source = SOURCES_MAP[str.substr(0, 2)];
    const id = str
        .substr(2)
        .split('')
        .map(function encodeMap(char) {
            return REVERSE_LETTER_MAP[char] || char;
        })
        .join('');

    return {
        id: id,
        source: source
    };
}

export function encodeString(str) {
    return str
        .split('')
        .map(function encodeMap(char) {
            return LETTER_MAP[char] || char;
        })
        .join('');
}

export function decodeString(str) {
    return str
        .split('')
        .map(function encodeMap(char) {
            return REVERSE_LETTER_MAP[char] || char;
        })
        .join('');
}
