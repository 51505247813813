import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
    text-transform: none;
    vertical-align: initial;
    box-shadow: none;
    ${({ inline })=> (inline ? 'padding: 0;' : '')}
    ${({ inline })=> (inline ? '&:hover { background: none; opacity: 0.9; }' : '')}
    &:active {
        box-shadow: none;
    }
`;

function LRButton(props, ref) {
    const { children, inline, ...otherProps } = props;

    return (
        <StyledButton ref={ref} inline={inline ? 1 : 0} {...otherProps}>
            {children}
        </StyledButton>
    );
}

export default React.forwardRef(LRButton);
