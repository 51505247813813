import React, { useState } from 'react';
import { BaseModal } from '../../../components/Modals';
import { Box, Typography, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import { LRInput } from '../../../components/LRInput';
import PoweredByPng from '../../../images/powered-by-listreports.png';
import { CircleHeadshot } from '../../../components/CircleHeadshot';
import LRButton from '../../../components/LRButton';
import { callButtonTheme } from '../../../theme';
import { partial } from 'lodash-es';

export function GuestWelcomeModal({
    agent,
    open = false,
    onClose = ()=> {},
    onJoinMeeting = ()=> {},
    ...props
}) {
    const [name, setName] = useState('');

    function onSubmit(e) {
        e.preventDefault();
        onJoinMeeting(name);
    }

    return (
            <BaseModal 
                open={open} 
                onClose={onClose} 
                {...props}

                header={
                    <DialogTitle disableTypography style={{ padding: 0 }}>
                        {/* Headshot */}
                        <Box mb={2}>
                            <CircleHeadshot src={agent?.headshot} width="93px" mx="auto" />
                        </Box>

                        <Typography variant="h2" align="center" style={{ fontWeight: 'normal' }}>
                            Welcome to <b>{agent?.name}'s</b> virtual conference room!
                        </Typography>
                    </DialogTitle>
                }
                buttons={
                    <MuiThemeProvider theme={callButtonTheme}>
                        <LRButton 
                            type="submit"
                            disabled={!name} 
                            variant="contained" 
                            color="primary" 
                            style={{ minWidth: "140px" }} 
                            onClick={partial(onJoinMeeting, name)}
                        >
                            Join
                        </LRButton>
                    </MuiThemeProvider>
                }
            >
                <>
                    <form className="guestForm" onSubmit={onSubmit}>
                        <Box mb={2} mt={3}>
                            <Box mb={1}>
                                <label>
                                    <Typography variant="body2">
                                        Let them know who you are
                                    </Typography>
                                </label>
                            </Box>
                            <Box>
                                <LRInput 
                                    value={name}
                                    variant="outlined"
                                    fullWidth={true}
                                    placeholder="Enter your name"
                                    onChange={(e)=> setName(e.currentTarget.value)}
                                />
                            </Box>
                        </Box>
                    </form>

                    <Box textAlign="center" position="absolute" bottom="0" right="0" mr={1} mb={.5}>
                        <img src={PoweredByPng} alt="Powered by ListReports" style={{ width: '95px' }} />
                    </Box>
                </>
            </BaseModal>
    );
}
