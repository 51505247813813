import React from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { ConferenceVideo } from '../ConferenceVideo';
import styled from 'styled-components';

const Container = styled(Box)`
    max-height: 100%;
    max-width: 100%;
`;

export function ConferenceParticipants({
    participants,
    ...props
}) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    
    return (
        <Container
            px={1}
            {...props}
        >
            <Box 
                pb={1.5} 
                display="flex" 
                style={{
                    overflowX: 'auto',
                }}
            >
                {participants?.map((participant, i)=> {
                        return (
                            <ConferenceVideo
                                key={participant.id}
                                name={participant.user_name} 
                                videoTrack={participant.videoTrack}
                                audioTrack={participant.audioTrack}
                                showName
                                width={isXs ? '100px' : '150px'}
                                minWidth={isXs ? '100px' : '150px'}
                                border="1px solid #ccc"
                                borderRadius="4px"
                                overflow="hidden"
                                marginLeft={i === 0 ? 0 : 0.5}
                            />
                        )
                    })
                }
            </Box>
        </Container>
    );
}
