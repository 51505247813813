import Promise from 'bluebird';
import Logger from 'js-logger';
import { useState, useEffect, useRef } from 'react';
import { EXT_EVENT_START_SCREENSHARE } from '../../constants';
const { REACT_APP_LR_LIVE_EXTENSION_ID } = process.env;

const logger = Logger.get('useLrLiveExtension');

export function useLrLiveExtension() {

    const [userMediaStream, setUserMediaStream] = useState(null);
    const [extensionPopupOpened, setExtensionPopupOpened] = useState(0);
    const popupOpenedCount = useRef(0);

    const [extensionInstalled, setExtensionInstalled] = useState(false);


    async function startExtensionScreenshare() {
        const extensionResponse = await sendExtensionMessage({ type: EXT_EVENT_START_SCREENSHARE });
        logger.debug('extension stream response', extensionResponse);
        const { streamId } = extensionResponse;
        if(!streamId) {
            logger.error('Could not start extension screenshare, no stream ID returned');
            return false;
        }
        return navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                mandatory: {
                    chromeMediaSource: 'tab',
                    chromeMediaSourceId: streamId,
                }
            }})
            .then((stream) => {
                logger.debug('got user media stream', stream);
                setUserMediaStream(stream);
                return true;
            })
            .catch((err) => {
                logger.error('error getting user media', err);
                return false;
            });
    };


    async function stopExtensionScreenshare() {
        if(userMediaStream) {
            userMediaStream.getTracks().forEach(function (track) {
                track.stop();
            });
            logger.info('Stopped stream');
            setUserMediaStream(null);
            return true;
        } else {
            logger.warn('userMediaStream was never started');
            return false;
        }
    }



    function sendExtensionMessage(data) {
        return new Promise((resolve, reject)=> {
            if (!chrome) {
                reject(new Error('Could not find chrome instance'));
                return;
            };
            chrome.runtime.sendMessage(REACT_APP_LR_LIVE_EXTENSION_ID, data, resolve);
        });
    }

    const requiredVersion = 1;

    // setup the chrome listener for the browser window popup so we can start screenshare
    useEffect(()=> {

        async function onMessage(message) {
            const { data } = message;
            if (data && data.type === 'SCREENSHARE_START') {
                await Promise.delay(500);
                popupOpenedCount.current = popupOpenedCount.current + 1;
                setExtensionPopupOpened(popupOpenedCount.current);
            }
        }
        window.addEventListener('message', onMessage);
        // return ()=> {
        //     window.removeEventListener('message', onMessage);
        // }
    }, [])

    useEffect(() => {
        function checkExtensionExists() {
            // check if extensions is installed
            logger.debug('check if lr-live extension was installed');
            let hasExtension = false;
            if (!REACT_APP_LR_LIVE_EXTENSION_ID || !chrome) {
                setExtensionInstalled(false);
            } else {
                chrome.runtime.sendMessage(
                    REACT_APP_LR_LIVE_EXTENSION_ID,
                    { type: 'version' },
                    function (reply) {
                        logger.debug('extension check reply', reply);
                        if (reply) {
                            if (reply.version) {
                                if (reply.version >= requiredVersion) {
                                    hasExtension = true;
                                }
                            }
                        }
                        else {
                            hasExtension = false;
                        }
                        logger.debug('hasExtension', hasExtension);
                        setExtensionInstalled(hasExtension);
                    });
            }
        }
        try {
            checkExtensionExists();
        } catch (err) {
            logger.error('error checking extension exists', err);
        }
    }, []);


    




    return {
        extensionPopupOpened,
        userMediaStream,
        extensionInstalled,
        sendExtensionMessage,
        startExtensionScreenshare,
        stopExtensionScreenshare,
    };
}
