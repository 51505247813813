import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const CircleImage = styled(Box)`
    border-radius: 50%;
    background: url('${({ src })=> src}') center center no-repeat;
    background-size: cover;
`;

export function CircleHeadshot({
    src,
    ...props
}) {
    return (
        <CircleImage src={src} {...props}>
            <Box pb="100%" />
        </CircleImage>
    );
}
