import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Logger from 'js-logger';
import { forIn } from 'lodash-es';
import useBrowserContextCommunication from 'react-window-communication-hook';
import { AppBar, Tabs, Tab, Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    REMOTE_EVENT_TOGGLE_VIDEO,
    REMOTE_EVENT_TOGGLE_SCREENSHARE,
    REMOTE_EVENT_TOGGLE_AUDIO,
    MEETING_EVENT_STATE_UPDATED,
    MEETING_EVENT_LOCAL_PARTICIPANT_UPDATED,
    REMOTE_EVENT_LOADED,
    REMOTE_EVENT_CLOSED,
    MEETING_EVENT_REMOTE_INIT,
    MEETING_EVENT_DISABLE_TOOLBAR_UPDATED,
    REMOTE_EVENT_LEAVE_MEETING,
    REMOTE_EVENT_CLOSE_PRESENTER_VIEW,
    REMOTE_EVENT_ADD_FAKE_PARTICIPANT,
    REMOTE_EVENT_ON_FOCUS,
    REMOTE_EVENT_ON_BLUR,
    REMOTE_STATE_SCREENSHARE_NOT_STARTED,
    REMOTE_STATE_SCREENSHARE_STARTED,
    REMOTE_STATE_SCREENSHARE_STOPPED,
} from '../../constants';
import { ConferenceToolbar } from '../../components/ConferenceToolbar';
import { TiledConferenceVideos } from '../../components/TiledConferenceVideos';
import { useDailycoVideoConference } from '../../hooks/useDailycoVideoConference';
import { ConferenceParticipants } from '../../components/ConferenceParticipants';
import { Messenger } from '../../components/Messenger';
const { REACT_APP_ENABLE_FAKE_PARTICIPANTS = false } = process.env;

const logger = Logger.get('Room');

const ParticipantContainer = styled(AppBar)`
    background: rgba(18,181,234,0);
    // backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    z-index: 2;
`;


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            display="inline"
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box flexDirection="column" flexGrow={1} flex={1} display="flex">{children}</Box>}
        </Typography>
    );
}


export function RoomRemote() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { jwt: ROOM_TOKEN = '' } = queryString.parse(location.search);
    const queryParams = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [communicationState, postMessage] = useBrowserContextCommunication("roomRemoteControl");
    const { roomId: ROOM_ID } = queryParams;
    const [roomState, setRoomState] = useState(null);
    const [localParticipant, setLocalParticipant] = useState(null);
    const [screenshareState, setScreenshareState] = useState(REMOTE_STATE_SCREENSHARE_NOT_STARTED);
    const [disableToolbar, setDisableToolbar] = useState(false);
    const [videoParticipants, setVideoParticipants] = useState([]);
    const [audioParticipants, setAudioParticipants] = useState([]);
    const [historicalMessengerMessages, setHistoricalMessengerMessages] = useState([]);
    const {
        callObject,
        participants,
        joinMeeting,
    } = useDailycoVideoConference({ roomId: ROOM_ID, roomToken: ROOM_TOKEN });
    const isConnected = !!callObject;

    const sendPostMessage = (eventName)=> ()=> {
        logger.debug('Sending post message');
        postMessage({ e: eventName });
    };

    const addFakeParticipant = () => {
        if (!isConnected) return;

        logger.debug('Adding fake participant');
        callObject.addFakeParticipant({ aspectRatio: '16/9' });
    }

    useEffect(() => {
        postMessage({ e: REMOTE_EVENT_LOADED });
    }, []);

    useEffect(()=> {
        document.title = 'Presentation Remote'
    }, []);

    /**
     * Auto join meeting
     */
    useEffect(() => {
        joinMeeting();
        logger.debug('______________________ joining meeting');
    }, []);

    /**
     * Remove token from the URL. This prevents
     * the user from accidently sharing it with 
     * someone else.
     */
    useEffect(() => {
        if (ROOM_TOKEN && !location.hostname === "localhost") {
            delete queryParams.jwt;
            delete queryParams.roomId;

            const newParams = queryString.stringify(queryParams);
            const newLocation = `${location.pathname}${newParams.length ? `?${newParams}` : ''}`;

            history.replace(newLocation);
        }
    }, [ROOM_TOKEN])

    /**
     * adds video particpants for the room
     */
    useEffect(() => {
        if (!participants) return;

        const audioParticipants = [];
        const videoParticipants = [];

        forIn(participants, (participant, id) => {
            if (id === 'local' && !participant.screen) return;
            // if it's the same user joining with the same token, ignore
            if(participant.user_id === localParticipant.user_id) return;

            participant.id = id;
            logger.debug('new participant', participant);
            if (REACT_APP_ENABLE_FAKE_PARTICIPANTS || participant.video || participant.screen) {
                videoParticipants.push(participant);
            } else {
                audioParticipants.push(participant);
            }
        });
        setVideoParticipants(videoParticipants);
        setAudioParticipants(audioParticipants);
    }, [participants]);

    /**
     * if localParticipants stops screensharing, kill the window
     */
    useEffect(()=> {
        if(!localParticipant) return;

        if(localParticipant.screen && screenshareState === REMOTE_STATE_SCREENSHARE_NOT_STARTED) {
            setScreenshareState(REMOTE_STATE_SCREENSHARE_STARTED);
        }
        if(!localParticipant.screen && screenshareState === REMOTE_STATE_SCREENSHARE_STARTED) {
            setScreenshareState(REMOTE_STATE_SCREENSHARE_STOPPED);
        }
    }, [localParticipant]);

    /**
     * watch the screenshare state to perform actions
     */
    useEffect(()=> {
        if(screenshareState === REMOTE_STATE_SCREENSHARE_STOPPED) {
            logger.debug('should close window now');
            window.close();
        }
    }, [screenshareState]);

    /**
     * Postmessage communication handler from the associated room
     */
    useEffect(() => {
        logger.debug('onPostMessage communicationState', communicationState);

        const { lastMessage } = communicationState;
        if (!lastMessage) return;
        const { e, p } = lastMessage;
        switch(e) {
            case MEETING_EVENT_REMOTE_INIT:
                logger.debug('MEETING_REMOTE_INIT', p);
                const { local, roomState, disableConfrenceToolbar, historicalMessengerMessages } = p;
                setRoomState(roomState);
                setLocalParticipant(local);
                setDisableToolbar(disableConfrenceToolbar);
                setHistoricalMessengerMessages(historicalMessengerMessages);
                break;
            case MEETING_EVENT_STATE_UPDATED:
                logger.debug('MEETING_STATE_UPDATED', p);
                setRoomState(p);
                break;
            case MEETING_EVENT_LOCAL_PARTICIPANT_UPDATED:
                logger.debug('LOCAL_PARTICIPANT_UPDATED', p);
                setLocalParticipant(p);
                break;
            case MEETING_EVENT_DISABLE_TOOLBAR_UPDATED:
                logger.debug('DISABLE_TOOLBAR_UPDATED', p);
                setDisableToolbar(p);
                break;
            default:
                logger.warn('Unknown postMessage event', e);
                break;
        }
    }, [communicationState]);


    /**
     * Send remote closed event on unmount
     */
    useEffect(() => {
        const onUnload = () => {
            postMessage({ e: REMOTE_EVENT_CLOSED })
        };

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);

    /**
     * Check window visibility to notify parent room if it's hidden
     */
    useEffect(() => {
        function onFocus() {
            postMessage({ e: REMOTE_EVENT_ON_FOCUS });
        }

        function onBlur() {
            postMessage({ e: REMOTE_EVENT_ON_BLUR });
        }
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    return (
        <div className={classes.root}>
            <Box flex="1">
                <AppBar position="static">
                    <Tabs
                        value={tabIndex}
                        onChange={(event, newValue) => setTabIndex(newValue)}
                        aria-label="control tabs"
                    >
                        <Tab label="Chat" />
                        <Tab label="Participants" />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabIndex} index={0}>
                    {localParticipant && (
                        <Box
                            height="calc(100vh - 148px)"
                            border="1px solid rgb(221, 221, 221)"
                        >
                            <Messenger 
                                className="RoomRemote-Messenger"
                                localUser={localParticipant}
                                userName={localParticipant.user_name}
                                userId={localParticipant.user_id}
                                roomId={ROOM_ID}
                                additionalMessages={historicalMessengerMessages}
                            />
                        </Box>
                    )}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100vh - 148px)"
                    >
                        {isConnected && videoParticipants.length > 0 && (
                            <TiledConferenceVideos
                                participants={videoParticipants}
                            />
                        )}
                        {isConnected && audioParticipants?.length > 0 && (
                            <ParticipantContainer
                                component={Box}
                                position="static"
                                color="transparent"
                                elevation={0}
                                pt={1}
                            >
                                <ConferenceParticipants
                                    mx="auto"
                                    participants={audioParticipants}
                                />
                            </ParticipantContainer>
                        )}

                        {isConnected && !videoParticipants.length && !audioParticipants.length && (
                            <Typography variant="h4">No Participants yet</Typography>
                        )}

                    </Box>
                </TabPanel>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <ConferenceToolbar
                    remoteControl={true}
                    roomState={roomState}
                    videoEnabled={localParticipant?.video}
                    audioEnabled={localParticipant?.audio}
                    screenShareEnabled={localParticipant?.screenVideoTrack}
                    onToggleVideoClick={sendPostMessage(REMOTE_EVENT_TOGGLE_VIDEO)}
                    onToggleAudioClick={sendPostMessage(REMOTE_EVENT_TOGGLE_AUDIO)}
                    onToggleScreenShareClick={sendPostMessage(REMOTE_EVENT_TOGGLE_SCREENSHARE)}
                    onEndMeetingClick={sendPostMessage(REMOTE_EVENT_LEAVE_MEETING)}
                    onPresenterViewClick={localParticipant?.screenVideoTrack
                        ? sendPostMessage(REMOTE_EVENT_CLOSE_PRESENTER_VIEW)
                        : sendPostMessage(REMOTE_EVENT_TOGGLE_SCREENSHARE)
                    }
                    onAddFakeUserClick={addFakeParticipant}
                    disabled={disableToolbar}
                />
            </Box>
        </div>
    )
}




/*
* Used from the room component to pass messages between the remote control
*/
export function useRemoteControl({
    communicationState,
    postMessage,
    local,
    roomState,
    toggleVideo = ()=> {},
    toggleAudio = ()=> {},
    toggleScreenShare = ()=> {},
    disableConfrenceToolbar,
    togglePresenterView = ()=> {},
    addFakeParticipant = ()=> {},
    setRemoteFocused = ()=> {},
    leaveMeeting = ()=> {},
    historicalMessengerMessages = [],
}) {

    useEffect(() => {
        logger.debug('onPostMessage communicationState', communicationState);

        function sendInitialData() {
            postMessage({ e: MEETING_EVENT_REMOTE_INIT, p: { local, roomState, disableConfrenceToolbar, historicalMessengerMessages } });
        }

        const { lastMessage } = communicationState;
        if (!lastMessage) return;
        const { e } = lastMessage;
        switch (e) {
            case REMOTE_EVENT_TOGGLE_VIDEO:
                toggleVideo();
                break;
            case REMOTE_EVENT_TOGGLE_AUDIO:
                toggleAudio();
                break;
            case REMOTE_EVENT_TOGGLE_SCREENSHARE:
                toggleScreenShare();
                break;
            case REMOTE_EVENT_ADD_FAKE_PARTICIPANT:
                addFakeParticipant();
                break;
            case REMOTE_EVENT_LOADED:
                sendInitialData();
                break;
            case REMOTE_EVENT_CLOSED:
            case REMOTE_EVENT_CLOSE_PRESENTER_VIEW:
                togglePresenterView(false);
                break;
            case REMOTE_EVENT_LEAVE_MEETING:
                leaveMeeting();
                break;
            case REMOTE_EVENT_ON_FOCUS:
                setRemoteFocused(true);
                break;
            case REMOTE_EVENT_ON_BLUR:
                setRemoteFocused(false);
                break;
            default:
                logger.warn('Unknown event passed', e);
                break;
        }

    }, [communicationState]);

    useEffect(() => {
        postMessage({ e: MEETING_EVENT_LOCAL_PARTICIPANT_UPDATED, p: local });
    }, [local]);
    useEffect(() => {
        postMessage({ e: MEETING_EVENT_STATE_UPDATED, p: roomState });
    }, [roomState]);
    useEffect(() => {
        postMessage({ e: MEETING_EVENT_DISABLE_TOOLBAR_UPDATED, p: disableConfrenceToolbar });
    }, [disableConfrenceToolbar])

    /**
     * Close remote on unmount
     */
    useEffect(() => {
        const onUnload = () => {
            logger.debug('turning off presenter view before unload');
            togglePresenterView(false);
        };

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    });
}
