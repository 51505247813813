import React from 'react'
import { Snackbar, makeStyles, Button } from '@material-ui/core';
import { LrLiveApi } from '../../api';
import { COLOR_RED } from '../../constants';
import Logger from 'js-logger';

const logger = Logger.get('RecordingConfirmSnackbar');

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    deleteRecording: {
        color: COLOR_RED,
    }
}));

export function RecordingConfirmSnackbar(props) {
    const {
        handleExited = ()=> {},
        handleClose = ()=> {},
        open = false,
        roomId = null,
        recordingId = null,
        jwt = null,
    } = props;

    const classes = useStyles();

    const handleDelete = async ()=> {
        logger.debug(`Deleting Recording ${recordingId} for room ${roomId}`);
        if(recordingId) {
            const result = await LrLiveApi.deleteRecording(roomId, recordingId, jwt)
            .catch((err)=> {
                logger.error('Error deleting the recording', err);
            });
            logger.info(`Deleted recording`, result);
        }
        handleClose();
    }

    return (
        <Snackbar
            key='recordingInfoSnackbar'
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
            onExited={handleExited}
            message='Do you want to keep this recording?'
            action={
                <React.Fragment>
                    <Button color="primary" size="small" onClick={handleClose}>
                        KEEP
                    </Button>
                    <Button className={classes.deleteRecording} size="small" onClick={handleDelete}>
                        DISCARD
                    </Button>
                </React.Fragment>
            }
        />
    )
}

