import React from 'react';
import GenericPage from '../../components/GenericPageCenteredText';

export function ThankYouPage() {
    return (
        <GenericPage
            variant='h4'
            color='primary'
            text='Thank you for using ListReports Live'
        />
    );
}
