import React, { useState, useEffect, useRef } from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import Logger from 'js-logger';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { ConferenceVideo } from '../ConferenceVideo';
import { useTileLayout } from '../../hooks/useTileLayout';


const logger = Logger.get('TiledConferenceVideos');
const TileContainer = styled(Box)`
    // width: 80%;
    width: ${({ totalParticipants })=> totalParticipants > 15 ? '90%' : '80%'};
    height: 95%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 599px) {
        width: 100%;
    }
`;
const Tile = styled(Box)`
    position: relative;
    // margin: 2px;
`;

export function TiledConferenceVideos({
    participants,
    ...props
}) {
    const totalParticipants = participants?.length || 0;
    const containerEl = useRef(null);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const [containerWidth, setContainerWidth] = useState();
    const [containerHeight, setContainerHeight] = useState();
    const { columnWidthPerc } = useTileLayout({
        totalItems: totalParticipants,
        containerMaxWidth: containerWidth,
        containerMaxHeight: containerHeight,
    });
    const {
        windowWidth,
        windowHeight,
    } = useWindowDimensions();

    useEffect(()=> {
        if(!isXs) {
            setContainerWidth(containerEl.current?.offsetWidth);
            setContainerHeight(containerEl.current?.offsetHeight);
        }
    }, [windowWidth, windowHeight, isXs]);

    return (
        <TileContainer 
            ref={containerEl}
            className="tile-container"
            totalParticipants={participants?.length}

            display={!isXs && 'flex'}
            justifyContent="center"
            alignItems="flex-start"
            maxHeight="100%"
            overflow={isXs ? 'visible' : 'hidden'}
            // bgcolor="#f0f"
        >
            <Box
                width="100%"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                pb={isXs && 25}
            >
                {participants?.map((participant)=> (
                    <Tile key={participant.id} width={`${isXs ? 100 : columnWidthPerc}%`}>
                        <ConferenceVideo
                            name={participant.user_name}
                            showName
                            isLarge
                            isLocalUser={participant.id === 'local' && !participant?.screenVideoTrack}
                            audioTrack={participant?.audioTrack}
                            videoTrack={participant?.screenVideoTrack || participant?.videoTrack}

                            border="1px solid #fff"
                        />
                    </Tile>
                ))}
            </Box>
        </TileContainer>
    );
}
