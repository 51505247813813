import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';

export function StillHereDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose(true);
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Are you still there?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Click yes to continue your meeting.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
