import { observable, computed } from "mobx";

export class RoomStore {
    @observable _user_name = '';
    @observable participant_names = {};

    constructor(RootStore) {
        this.RootStore = RootStore;
    }

    // Getters and Setters

    /**
     * user_name
     * 
     * We are using a getter and setter just incase we want to 
     * manipulate how we display the persons name.
     */
    @computed
    get user_name() {
        return this._user_name;
    }

    set user_name(value) {
        this._user_name = value;
    }
}
