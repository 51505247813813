import React from 'react';
import { 
    AlbumSharp as RecordingIcon,
} from '@material-ui/icons';
import { recordButtonTheme } from '../../../theme';
import { MuiThemeProvider } from '@material-ui/core';
import { ToolbarButton } from '../ToolbarButton';
import Logger from 'js-logger';
import { MEETING_EVENT_RECORDING_STARTED } from '../../../constants';

const logger = Logger.get('CallButton');

export function RecordButton({
    disabled,
    recordingState = null,
    onClick = ()=> {},
    ...props
}) {
    const isRecording = recordingState === MEETING_EVENT_RECORDING_STARTED;
    logger.debug('isRecording', isRecording);

    return (
        <MuiThemeProvider theme={recordButtonTheme}>
            <ToolbarButton 
                {...props} 
                disabled={disabled} 
                color={isRecording ? 'secondary' : 'primary'} 
                onClick={onClick}
            >
                <RecordingIcon />
            </ToolbarButton>
        </MuiThemeProvider>
    );
}
