import React from 'react';
import { Box, MuiThemeProvider, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { agentBGTheme } from '../../../theme';
import { CircleHeadshot } from '../../../components/CircleHeadshot';
import PoweredByPng from '../../../images/powered-by-listreports.png';
import phoneFormatter from 'phone-formatter';

export function AgentBG({
    agent,
    lrLive = null,
    ...props
}) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    let welcomeText = 'Welcome to my virtual conference room!';
    if (lrLive) {
        switch (lrLive.type) {
            case 'digital_open_house':
                welcomeText = 'Welcome to my digital open house!';
                break;
            default:
                break;
        }
    }

    return (
        <MuiThemeProvider theme={agentBGTheme}>
            <Box
                bgcolor="#fff"
                borderRadius="8px"
                width="600px"
                px={isXs ? 2 : 4}
                pb={isXs ? 1 : 3}
                pt={0}
                mt={!isXs && -2}
                textAlign="center"
                {...props}
            >
                {/* Headshot */}
                <Box mt={-5} mb={2}>
                    <CircleHeadshot src={agent?.headshot} width="93px" mx="auto" />
                </Box>
                
                {/* Title */}
                <Box>
                    <Typography variant="h1">
                        {welcomeText}
                    </Typography>
                </Box>
                
                {/* Separator */}
                <Box 
                    my={4}
                    mx="auto"
                    bgcolor="#ccc"
                    height="4px"
                    width="40px"
                />
                
                {/* Name / Company */}
                <Box mb={3}>
                    <Box mb={1}>
                        <Typography variant="h2">
                            {agent?.name}
                        </Typography>
                    </Box>

                    <Typography variant="h3">
                        {agent?.company}
                    </Typography>
                </Box>
                
                {/* Phone */}
                <Box mb={3}>
                    <Box mb={1}>
                    <Typography color="primary" style={{ textTransform: 'uppercase' }}>
                            <b>Phone:</b>
                        </Typography>
                    </Box>

                    <Typography variant="h3">
                        {phoneFormatter.format(agent?.phone, '(NNN) NNN-NNNN')}
                    </Typography>
                </Box>
                
                {/* Email */}
                <Box>
                    <Box mb={1}>
                        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
                            <b>Email:</b>
                        </Typography>
                    </Box>

                    <Typography variant="h3">
                        {agent?.email}
                    </Typography>
                </Box>

                <Box textAlign="center" position="relative" top={isXs ? '40px' : '72px'}>
                    <img src={PoweredByPng} alt="Powered by ListReports" style={{ width: '95px' }} />
                </Box>
            </Box>
        </MuiThemeProvider>
    )
}
