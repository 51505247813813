import React, { useEffect, useState, useMemo } from 'react';
import { Box, useTheme, useMediaQuery, Tooltip, withTheme } from '@material-ui/core';
import { 
    Mic as MicIcon, 
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
    DesktopWindows as ScreenShreIcon,
    DesktopAccessDisabled as ScreenShareOffIcon,
    Slideshow as SlideshowIcon,
    Add as AddIcon,
    Chat as ChatIcon,
    Settings as SettingsIcon,
    Call as CallIcon, 
    CallEnd as CallEndIcon,
    AlbumSharp as RecordingIcon,
    FlipCameraIos as FlipCameraIosIcon,
} from '@material-ui/icons';
import { CallButton } from './CallButton';
import { RecordButton } from './RecordButton';
import { ToolbarButton } from './ToolbarButton';
import { MEETING_JOINED, MEETING_JOINING, MEETING_LEAVING, MEETING_IDLE } from '../../constants';
import Logger from 'js-logger';
import DailyIframe from '@daily-co/daily-js';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { partial } from 'lodash-es';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { 
    REACT_APP_ENABLE_FAKE_PARTICIPANTS = false,
    NODE_ENV:ENVIRONMENT = 'production',
} = process.env;
const logger = Logger.get('ConferenceToolbar');

export const ButtonTooltip = styled((props)=> (
    <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
    & .tooltip {
        background: #fff;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        font-size: 0.875rem;
        padding: 4px 16px;
    }
`;
const BtnContainer = withTheme(styled(Box)`
    transition: ${({ theme, allowAnimations })=> (
        allowAnimations
         ? (
            theme.transitions.create(['margin', 'opacity'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })
         )
         : 'none'
    )};
`);
BtnContainer.propTypes = {
    ...BtnContainer.propTypes,
    allowAnimations: PropTypes.bool,
}

export function ConferenceToolbar({
    slideshow,
    roomState,
    recordingState,
    disabled,
    allowRecording = false,
    isOwner = false,
    remoteControl = false,
    videoEnabled = false,
    audioEnabled = false,
    screenShareEnabled = false,
    screenShareParticipant,
    isSomeoneElseScreenSharing = false,
    totalVideoDevices = 0,
    allowBtnAnimations = false,
    onStartMeetingClick = ()=> {},
    onEndMeetingClick = ()=> {},
    onToggleAudioClick = ()=> {},
    onToggleVideoClick = ()=> {},
    onToggleScreenShareClick = ()=> {},
    onPresenterViewClick = ()=> {},
    onRecordingClick = ()=> {},
    onCycleCameraClick = ()=> {},
    onToggleChatClick = ()=> {},
    onSettingsClick = ()=> {},

    // A Test only function. To use uncomment the button below
    onAddFakeUserClick = ()=> {},
}) {
    const theme = useTheme();
    const isLteSmBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
    // if it's in remove form, we don't want to hide any elements
    const isLteSm = remoteControl ? false : isLteSmBreakPoint;
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const hasJoinedMeeting = roomState === MEETING_JOINED;
    const isConnectingDisconnectingToMeeting = !!~[MEETING_JOINING, MEETING_LEAVING].indexOf(roomState);
    const isMeetingIdle = roomState === MEETING_IDLE;
    const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
    const [displayScreenshare, setDisplayScreenshare] = useState(true);
    const screenshareSupported = !isLteSm && !remoteControl && DailyIframe.supportedBrowser().supportsScreenShare && displayScreenshare;
    const presentationButtonSupported = !isLteSm && (remoteControl || (slideshow && displayScreenshare));
    const recordingSupported = !isLteSm && allowRecording;
    const allButtons = [
        {
            id: 'call_btn',
            component: (
                <ButtonTooltip title={hasJoinedMeeting ? 'Leave' : 'Start'}>
                    <Box>
                        <CallButton 
                            disabled={isConnectingDisconnectingToMeeting}
                            isOnCall={hasJoinedMeeting} 
                            onStartCallClick={onStartMeetingClick}
                            onEndCallClick={onEndMeetingClick}
                        />
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: hasJoinedMeeting ? 'Leave' : 'Start',
            icon: hasJoinedMeeting ? <CallEndIcon /> : <CallIcon />,
            onClick: partial(handleSeedDialClick, (()=> hasJoinedMeeting ? onEndMeetingClick() : onStartMeetingClick())),
            flexOrder: isLteSm ? 3 : 1,
            hide: false,
        },
        {
            id: 'mic_btn',
            component: (
                <ButtonTooltip title={audioEnabled ? 'Mute' : 'Unmute'} >
                    <Box>
                        <ToolbarButton 
                            color="secondary" 
                            disabled={disabled || isMeetingIdle} 
                            onClick={onToggleAudioClick}
                        >
                            {audioEnabled ? <MicIcon /> : <MicOffIcon />}
                        </ToolbarButton>
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: audioEnabled ? 'Mute' : 'Unmute',
            icon: audioEnabled ? <MicIcon /> : <MicOffIcon />,
            onClick: partial(handleSeedDialClick, onToggleAudioClick),
            flexOrder: isLteSm ? 2 : 3,
            hide: false,
        },
        {
            id: 'video_btn',
            component: (
                <ButtonTooltip title={videoEnabled ? 'Stop Video' : 'Start Video'} >
                    <Box>
                        <ToolbarButton 
                            color="secondary" 
                            disabled={disabled || isMeetingIdle} 
                            onClick={onToggleVideoClick}
                        >
                            {videoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
                        </ToolbarButton>
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: videoEnabled ? 'Stop Video' : 'Start Video',
            icon: videoEnabled ? <VideocamIcon /> : <VideocamOffIcon />,
            onClick: partial(handleSeedDialClick, onToggleVideoClick),
            flexOrder: 4,
            hide: false,
        },
        // {
        //     id: 'cycle_camera_btn',
        //     component: (
        //         <ButtonTooltip title="Cycle Camera" >
        //             <Box>
        //                 <ToolbarButton 
        //                     color="secondary" 
        //                     disabled={disabled || isMeetingIdle} 
        //                     onClick={onToggleVideoClick}
        //                 >
        //                     {<FlipCameraIosIcon />}
        //                 </ToolbarButton>
        //             </Box>
        //         </ButtonTooltip>
        //     ),
        //     tooltip: 'Cycle Camera',
        //     icon: <FlipCameraIosIcon />,
        //     onClick: partial(handleSeedDialClick, onCycleCameraClick),
        //     flexOrder: 4,
        //     hide: !(isLteSm && totalVideoDevices > 1),
        // },
        {
            id: 'chat_btn',
            component: (
                <ButtonTooltip title="Chat">
                    <Box>
                        <ToolbarButton 
                            color="secondary" 
                            disabled={disabled || isMeetingIdle} 
                            onClick={onToggleChatClick}
                        >
                            <ChatIcon />
                        </ToolbarButton>
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: 'Chat',
            icon: <ChatIcon />,
            onClick: partial(handleSeedDialClick, onToggleChatClick),
            flexOrder: isSm ? 1 : 4,
            hide: false,
        },
        {
            id: 'screenshare_btn',
            component: (
                <ButtonTooltip title={isSomeoneElseScreenSharing ? `${screenShareParticipant?.user_name || 'A Participant'} is already sharing` : 'Share'}>
                    <Box>
                        <ToolbarButton 
                            color="secondary" 
                            disabled={disabled || isMeetingIdle || isSomeoneElseScreenSharing} 
                            onClick={onToggleScreenShareClick}
                        >
                            {screenShareEnabled ? <ScreenShareOffIcon /> : <ScreenShreIcon />}
                        </ToolbarButton>
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: 'Share',
            icon: screenShareEnabled ? <ScreenShareOffIcon /> : <ScreenShreIcon />,
            onClick: partial(handleSeedDialClick, onToggleScreenShareClick),
            flexOrder: 4,
            hide: !screenshareSupported,
        },
        {
            id: 'presentation_btn',
            component: (
                <ButtonTooltip title="Present">
                    <Box>
                        <ToolbarButton
                            disabled={disabled || isMeetingIdle}
                            color="secondary"
                            onClick={onPresenterViewClick}
                        >
                            {screenShareEnabled ? <ScreenShareOffIcon /> : <SlideshowIcon />}
                        </ToolbarButton>
                        </Box>
                </ButtonTooltip>
            ),
            tooltip: 'Present',
            icon: screenShareEnabled ? <ScreenShareOffIcon /> : <SlideshowIcon />,
            onClick: partial(handleSeedDialClick, onPresenterViewClick),
            flexOrder: 4,
            hide: !presentationButtonSupported,
        },
        {
            id: 'record_btn',
            component: (
                <ButtonTooltip title="Record">
                    <Box>
                        <RecordButton
                            disabled={disabled || isMeetingIdle}
                            onClick={onRecordingClick}
                            recordingState={recordingState}
                        />
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: 'Record',
            icon: <RecordingIcon />,
            onClick: partial(handleSeedDialClick, onRecordingClick),
            flexOrder: 4,
            hide: !recordingSupported,
        },
        {
            id: 'settings_btn',
            component: (
                <ButtonTooltip title="Settings">
                    <Box>
                        <ToolbarButton 
                            color="secondary" 
                            disabled={disabled || isMeetingIdle} 
                            onClick={onSettingsClick}
                        >
                            <SettingsIcon />
                        </ToolbarButton>
                    </Box>
                </ButtonTooltip>
            ),
            tooltip: 'Settings',
            icon: <SettingsIcon />,
            onClick: partial(handleSeedDialClick, onSettingsClick),
            flexOrder: 4,
            hide: false,
        },
    ].filter((btn)=> !btn.hide);
    // Commented out code below is related to the cycle camera button
    // Once we fix the issue with it we can uncomment the code below.
    const buttons = (!remoteControl && isXs && allButtons.slice(0, (/* totalVideoDevices > 1 ? 4 : */ 3))) || allButtons;
    const speedDialButtons = (!remoteControl && isXs && allButtons.slice(/* totalVideoDevices > 1 ? 4 : */ 3)) || [];

    // UseEffects

    useEffect(() => {
        // if slideshow is present then only the room owner can screenshare
        const shouldDisplayScreenshare = (slideshow != null)
            ? (slideshow && isOwner)
            : true;
        setDisplayScreenshare(shouldDisplayScreenshare);
        return () => {};
    }, [isOwner, slideshow])

    // Functions

    function handleSpeedDialOpenClose(value) {
        setIsSpeedDialOpen(value);
    }

    function handleSeedDialClick(func) {
        if(func) func();
        handleSpeedDialOpenClose(false);
    }

    return (
        <>
            {(ENVIRONMENT).toLowerCase() !== 'production' && REACT_APP_ENABLE_FAKE_PARTICIPANTS === 'true' && <Box 
                display="flex" 
                justifyContent="flex-end" 
                alignItems="center"
                padding={3}
                maxHeight="100px"
            >
                <Box
                    display="flex" 
                    justifyContent="flex-start" 
                    alignItems="center"
                >
                    <Box ml={5}>
                        <ToolbarButton color="secondary" disabled={roomState !== MEETING_JOINED} onClick={onAddFakeUserClick}>
                            <AddIcon />
                        </ToolbarButton>
                    </Box>
                </Box>
            </Box>}

            <Box 
                display="flex" 
                justifyContent={isLteSm ? 'center' : 'flex-end'} 
                alignItems="center"
                padding={3}
                maxHeight="100px"
            >
                <Box
                    display="flex" 
                    justifyContent="flex-start" 
                    alignItems="center"
                >
                    {buttons.map((btn, i, arr)=> (
                        <BtnContainer 
                            key={btn.id}
                            allowAnimations={allowBtnAnimations}
                            ml={!(isMeetingIdle && allowBtnAnimations) && .5}
                            mr={(isMeetingIdle && allowBtnAnimations) && (i !== (arr.length - 1)) ? '-56px' : .5}
                            order={btn.flexOrder}
                            zIndex={arr.length - i}
                            style={{
                                opacity: ((isMeetingIdle && allowBtnAnimations) && i !== 0) ? 0 : 1,
                            }}
                        >
                            {btn.component}
                        </BtnContainer>
                    ))}

                    {speedDialButtons?.length > 0 && (
                        <BtnContainer 
                            allowAnimations={allowBtnAnimations}
                            ml={(isMeetingIdle && allowBtnAnimations) ? '-60px' : .5}
                            mr={(isMeetingIdle && allowBtnAnimations) ? 0 : .5}
                            height="56px"
                            width="56px"
                            order={100}
                            zIndex={1}
                            position="relative"
                        >
                            <Box 
                                clone
                                position="absolute"
                                bottom="0"
                                left="0"
                            >
                                <SpeedDial
                                    ariaLabel="Settings"
                                    icon={<SpeedDialIcon />}
                                    open={isSpeedDialOpen}
                                    onOpen={partial(handleSpeedDialOpenClose, true)}
                                    onClose={partial(handleSpeedDialOpenClose, false)}
                                    FabProps={{
                                        color: 'secondary',
                                        disabled: disabled || isMeetingIdle,
                                    }}
                                >
                                    {speedDialButtons.map((btn)=> (
                                        <SpeedDialAction
                                            key={btn.id}
                                            icon={btn.icon}
                                            tooltipTitle={btn.tooltip}
                                            onClick={btn.onClick}
                                            tooltipOpen={true}
                                        />
                                    ))}
                                </SpeedDial>
                            </Box>
                        </BtnContainer>
                    )}
                </Box>
            </Box>
        </>
    );
}
