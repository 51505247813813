// eslint-disable-next-line
import axios from 'axios';
import Logger from 'js-logger';

const logger = Logger.get('portal-api');

const portalAxios = axios.create({
    baseURL: `${process.env.REACT_APP_PORTAL_URL}`,
});


// PUBLIC FUNCTIONS
export async function getLiveData(liveId) {
    try {
        const { data: { data } } = await portalAxios.get(`/v1/lr-live/${liveId}`);
        return data;
    } catch (err) {
        const {
            response = {},
            // request = {},
            // config = {}
        } = err;
        const { status, data } = response;
        logger.error('Error fetching lr-live object', err);
        if (status === 404) {
            return null;
        }
        throw new Error(data);
    }
}


export async function deleteRecording(roomId, recordingId, jwt) {
    try {
        const params = { jwt };
        const { data: { data } } = await portalAxios.delete(`/v1/lr-live/${roomId}/recordings/${recordingId}`, { params });
        return data;
    } catch (err) {
        const {
            response = {},
            // request = {},
            // config = {}
        } = err;
        const { status, data } = response;
        logger.error('Error deleting lr-live recording', data);
        if (status === 404) {
            return null;
        }
        throw new Error(data);
    }
}
