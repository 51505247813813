import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import LRSelect from '../../../components/LRSelect';
import Logger from 'js-logger';

const logger = Logger.get('Settings');

export function Settings({
    callObject,
    localUser,
    ...props
}) {
    const [videoDevices, setVideoDevices] = useState([]);
    const [micDevices, setMicDevices] = useState([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
    const [selectedMicDevice, setSelectedMicDevice] = useState('');

    // UseEffects

    useEffect(()=> {
        if(callObject) {
            setInputDevices(callObject, setSelectedVideoDevice, setSelectedMicDevice, setVideoDevices, setMicDevices);
        }
    }, [callObject, localUser?.video, localUser?.audio]);

    // Functions

    function onMicDeviceChange(e) {
        const value = e?.currentTarget.value;

        if(!value) return;

        const deviceId = value;
        setSelectedMicDevice(deviceId);
        callObject.setInputDevices({
            audioDeviceId: deviceId,
        });
    }   

    function onVideoDeviceChange(e) {
        const value = e?.currentTarget.value;

        if(!value) return;

        const deviceId = value;
        setSelectedVideoDevice(deviceId);
        callObject.setInputDevices({
            videoDeviceId: deviceId,
        });
    }   

    return (
        <Box
            className="RoomDrawer-Settings-Container"
            p={2}
            {...props}
        >
            {/* Mic */}
            <Box 
                className="RoomDrawer-Settings-Container-Setting"
                mb={2} 
            >
                <Box 
                    className="RoomDrawer-Settings-Container-Setting-Label"
                    mb={1}
                >
                    <label>
                        <Typography variant="body2">
                            Mic
                        </Typography>
                    </label>
                </Box>
                <Box>
                    <LRSelect 
                        value={selectedMicDevice}
                        onChange={onMicDeviceChange} 
                        fullWidth
                    >
                        <option value="">Select</option>

                        {micDevices.map((device)=> (
                            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                        ))}
                    </LRSelect>
                </Box>
            </Box>
            {/* END Audio */}
            
            {/* Mic */}
            <Box 
                className="RoomDrawer-Settings-Container-Setting"
                mb={2} 
            >
                <Box 
                    className="RoomDrawer-Settings-Container-Setting-Label"
                    mb={1}
                >
                    <label>
                        <Typography variant="body2">
                            Video
                        </Typography>
                    </label>
                </Box>
                <Box>
                    <LRSelect 
                        value={selectedVideoDevice} 
                        onChange={onVideoDeviceChange}
                        fullWidth
                    >
                        <option value="">Select</option>

                        {videoDevices.map((device)=> (
                            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                        ))}
                    </LRSelect>
                </Box>
            </Box>
            {/* END Video */}
        </Box>
    );
}

async function setInputDevices(callObject, setSelectedVideoDevice, setSelectedMicDevice, setVideoDevices, setMicDevices) {
    let devices;

    // Get ther current devices selected
    try {
        const { camera, mic } = await callObject.getInputDevices();
        setSelectedVideoDevice(camera?.deviceId || '');
        setSelectedMicDevice(mic?.deviceId || '');
        
        logger.debug('camera', camera);
        logger.debug('mic', mic);
    } catch (err) {
        Logger.error('[getInputDevices] Error', err);
        throw err;
    }


    // Get all available devices
    try {
        const { devices:foundDevices } = await callObject.enumerateDevices();
        devices = foundDevices;
    } catch (err) {
        Logger.error('[getInputDevices] Error', err);
        throw err;
    }

    const audioDevices = [];
    const micDevices = [];
    const videoDevices = [];

    devices.forEach((device)=> {
        if(device.label?.match(/(zoom|virtual)/gi)) return;

        if(device.kind === 'videoinput') {
            videoDevices.push(device);
        } else if(device.kind === 'audioinput') {
            micDevices.push(device);
        } else if(device.kind === 'audiooutput') {
            audioDevices.push(device);
        }
    });

    setVideoDevices(videoDevices);
    setMicDevices(micDevices);

    logger.debug('devices', devices);
    logger.debug('audioDevices', audioDevices);
    logger.debug('micDevices', micDevices);
    logger.debug('videoDevices', videoDevices);
}
