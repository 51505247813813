import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const LRInput = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
    }

    & .MuiOutlinedInput-input {
        padding: 12px 14px;
        font-size: 1rem;
    }
`;
