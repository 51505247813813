import React from 'react';
import { Comment } from './Comment';
import { Box } from '@material-ui/core';
import { JoinedMessenger } from './JoinedMessenger';

export function Message({ message, previousMessage, nextMessage, ...props }) {
    switch(message.type) {
        case 'message':
            return <Comment message={message} previousMessage={previousMessage} nextMessage={nextMessage} {...props} />;
        case 'joined':
            return <JoinedMessenger message={message} previousMessage={previousMessage} nextMessage={nextMessage} {...props} />;
        case 'left':
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    pt={~['left', 'joined'].indexOf(previousMessage?.type) ? 0 : 2}
                    pb={~['left', 'joined'].indexOf(nextMessage?.type) ? 1 : 2}
                >
                    <Box flexGrow=".5" height="1px" bgcolor="#bbb" />
                    <Box px={1} color="#bbb">
                        {message.userName} Left
                    </Box>
                    <Box flexGrow=".5" height="1px" bgcolor="#bbb" />
                </Box>
            );
        default:
            return <></>;
    }
}
