import React from 'react';
import styled from 'styled-components';
import { Drawer, Box, AppBar, BottomNavigation, IconButton, BottomNavigationAction, useTheme, useMediaQuery } from '@material-ui/core';
import { ROOM_DRAWER_WIDTH } from '../../../constants';
import { 
    ArrowBackIosRounded as BackArrowIcon,
    Chat as ChatIcon, 
    Settings as SettingsIcon,
} from '@material-ui/icons';
import { Messenger } from '../../../components/Messenger';
import { Settings } from '../Settings';

const UtilsDrawer = styled(Drawer)`
    width: ${ROOM_DRAWER_WIDTH}px;
    flex-shrink: 0;

    .MuiDrawer-paper {
        width: ${ROOM_DRAWER_WIDTH}px;
    }
`;

export function RoomDrawer({
    callObject,
    localUser,
    userName,
    userId,
    roomId,
    tabValue,
    open = false,
    onTabClick = ()=> {},
    onToggleDrawer = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <UtilsDrawer
            variant={isXs ? 'temporary' : 'persistent'}
            anchor="right"
            open={open}
            {...props}
        >
            <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                bgcolor="#f7f7f7"
            >
                <AppBar position="relative" elevation={0}>
                    <BottomNavigation value={tabValue} onChange={(_, newValue)=> onTabClick(newValue)}>
                        <Box
                            display="flex"
                            alignItems="center"
                            pl={.5}
                            width="31%"
                        > 
                            <IconButton
                                aria-label="open drawer"
                                edge="end"
                                onClick={onToggleDrawer}
                            >
                                <BackArrowIcon />
                            </IconButton>
                        </Box>

                        <BottomNavigationAction label="Chat" value="chat" icon={<ChatIcon />} />
                        <BottomNavigationAction label="Settings" value="settings" icon={<SettingsIcon />} />
                    </BottomNavigation>
                </AppBar>

                {/* 
                    Handled this way so we can keep the messages in memory
                    and we dont lose them when changing tabs.
                */}
                <Box height="100%" overflow="hidden" display={tabValue !== 'chat' && 'none'}>
                    <Messenger 
                        localUser={localUser}
                        userName={userName}
                        userId={userId}
                        roomId={roomId}
                    />
                </Box>

                {tabValue === 'settings' && (
                    <Settings
                        callObject={callObject}
                        localUser={localUser}
                    />
                )}
            </Box>
        </UtilsDrawer>
    )
}
