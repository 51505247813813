import React from 'react';
import styled from 'styled-components';
import { Box, Typography, withTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

const PageContainer = withTheme(styled(Box)`
    background: url(https://s3.amazonaws.com/media-p.slid.es/uploads/1302223/images/7256595/bg_housetile.png) no-repeat center center !important;
    background-size: cover !important;
`);

export default function GenericPage(props) {
    const {
        variant = 'h4',
        color = 'primary',
        text,
    } = props;

    return (
        <PageContainer
            style={{
                textAlign: 'center',
                background: 'url(https://s3.amazonaws.com/media-p.slid.es/uploads/1302223/images/7256595/bg_housetile.png) no-repeat center center !important',
                backgroundSize: 'cover !important',
                height: 'calc(var(--vh, 1vh) * 100)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                display="flex"
                flexGrow={1}
                width="90%"
                justifyContent="center"
                flexDirection="column"
            >
                <Typography variant={variant} color={color}>
                    {text}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                m={2}
            >
                <Link href="https://welcome.listreports.com">
                    <img srcSet="/images/logo@2x.png 2x, /images/logo.png 1x" src="/images/logo.png" height="30" alt="lr_logo" />
                </Link>
            </Box>
        </PageContainer>
    );
}
