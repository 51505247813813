import { useState, useEffect } from "react";
import Logger from "js-logger";
import { useDebouncedCallback } from 'use-debounce';

const logger = Logger.get('useTileLayout');

export function useTileLayout({
    totalItems,
    containerMaxWidth,
    containerMaxHeight,
    debounce = 50,
}) {
    const [columnWidthPerc, setColumnWidthPerc] = useState(100);
    const [calculate, cancelCalculate] = useDebouncedCallback(
        ()=> {
            if(totalItems) {
                const { tileWidthPercent } = calculateMaxColumnWidthPercent(totalItems, containerMaxWidth, containerMaxHeight);
                setColumnWidthPerc(tileWidthPercent);
            }

            return cancelCalculate;
        },
        debounce
    );

    useEffect(calculate, [totalItems, containerMaxWidth, containerMaxHeight]);

    return {
        columnWidthPerc,
    }
}

function calculateMaxColumnWidthPercent(totalItems, containerMaxWidth, containerMaxHeight, columns = 1) {
    const tileWidthPercent = Math.round(100 / columns);
    const currentTileWidth = containerMaxWidth * tileWidthPercent / 100;
    const currentTileHeight = Math.round(9/16 * currentTileWidth);
    const totalRowsHeight = Math.ceil(totalItems / columns) * currentTileHeight;

    if(totalRowsHeight > containerMaxHeight && columns < 100) return calculateMaxColumnWidthPercent(totalItems, containerMaxWidth, containerMaxHeight, (columns + 1));

    return {
        tileWidthPercent,
        totalColumns: columns,
    }
}
