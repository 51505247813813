import { useState, useEffect, useMemo } from "react";
import Logger from "js-logger";

const logger = Logger.get('useDailycoConferenceDevices');

export function useDailycoConferenceDevices(callObject) {
    const [videoDevices, setVideoDevices] = useState([]);
    const [micDevices, setMicDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
    const [selectedMicDevice, setSelectedMicDevice] = useState('');
    const localUser = useMemo(()=> callObject && callObject.participants().local, [callObject]);

    useEffect(()=> {
        if(!callObject) return;

        setInputDevices(
            callObject,
            setSelectedVideoDevice,
            setSelectedMicDevice,
            setVideoDevices,
            setMicDevices,
            setAudioDevices,
        );
    }, [callObject, localUser?.video, localUser?.audio]);

    return {
        videoDevices,
        micDevices,
        audioDevices,
        selectedVideoDevice,
        selectedMicDevice,
    };
}

async function setInputDevices(
        callObject, 
        setSelectedVideoDevice, 
        setSelectedMicDevice, 
        setVideoDevices, 
        setMicDevices,
        setAudioDevices,
    ) {
    let devices;

    // Get ther current devices selected
    try {
        const { camera, mic } = await callObject.getInputDevices();
        setSelectedVideoDevice(camera?.deviceId || '');
        setSelectedMicDevice(mic?.deviceId || '');
        
        logger.debug('camera', camera);
        logger.debug('mic', mic);
    } catch (err) {
        Logger.error('[getInputDevices] Error', err);
        throw err;
    }


    // Get all available devices
    try {
        const { devices:foundDevices } = await callObject.enumerateDevices();
        devices = foundDevices;
    } catch (err) {
        Logger.error('[getInputDevices] Error', err);
        throw err;
    }

    const audioDevices = [];
    const micDevices = [];
    const videoDevices = [];

    devices.forEach((device)=> {
        if(device.label?.match(/(zoom|virtual)/gi)) return;

        if(device.kind === 'videoinput') {
            videoDevices.push(device);
        } else if(device.kind === 'audioinput') {
            micDevices.push(device);
        } else if(device.kind === 'audiooutput') {
            audioDevices.push(device);
        }
    });

    setVideoDevices(videoDevices);
    setMicDevices(micDevices);
    setAudioDevices(audioDevices);

    logger.debug('devices', devices);
    logger.debug('audioDevices', audioDevices);
    logger.debug('micDevices', micDevices);
    logger.debug('videoDevices', videoDevices);
}
