import { useRealtime } from "../useRealtime";
import { useEffect, useState } from "react";
import Logger from "js-logger";
import { ReadyState } from "react-use-websocket";
import { REALTIME_ACTION_HISTORY, REALTIME_HISTORY } from "../../constants";
import { reject } from "lodash-es";

const logger = Logger.get('useCanJoinMeeting');
const USER_ID = '__CAN_JOIN__';

export function useCanJoinMeeting({
    maxParticipants = 1,
    room,
}) {
    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useRealtime({
        userName: ' ',
        userId: USER_ID,
        room,
    });
    const [canJoinMeeting, setCanJoinMeeting] = useState(false);
    const [hasCheckedCanJoinMeetingStatus, setHasCheckedCanJoinMeetingStatus] = useState(false);

    /**
     * Waits for the connection to be open and asks
     * for the history
     */
    useEffect(()=> {
        if(readyState === ReadyState.OPEN && room) {
            sendMessage({ action: REALTIME_ACTION_HISTORY });
        }
    }, [readyState])

    /**
     * Handles all the incoming messages from the 
     * realtime service.
     */
    useEffect(()=> {
        if(!lastMessage) return;

        const { data } = lastMessage;
        const { e, p } = JSON.parse(data);

        switch(e) {
            case REALTIME_HISTORY:
                if(hasCheckedCanJoinMeetingStatus) break;

                const { connections } = p;
                const totalParticipants = reject(connections, { userId: USER_ID }).length;
                
                setCanJoinMeeting(totalParticipants < maxParticipants);
                setHasCheckedCanJoinMeetingStatus(true);
                break;
            default:
                break;
        }
    }, [lastMessage]);

    return {
        canJoinMeeting,
        hasCheckedCanJoinMeetingStatus,
    }
}
