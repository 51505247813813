import React, { useState, useRef, useCallback } from 'react';
import Promise from 'bluebird';
import { Box, DialogContent, AppBar, useTheme, useMediaQuery, withTheme } from '@material-ui/core';
import { SettingsRemote as SettingsRemoteIcon } from '@material-ui/icons';
import useBrowserContextCommunication from 'react-window-communication-hook';
import { MEETING_IDLE, MEETING_JOINED, MEETING_ERROR, MEETING_LEAVING, MEETING_EVENT_RECORDING_STARTED, MEETING_EVENT_RECORDING_STOPPED, ROOM_DRAWER_WIDTH, ROOM_MAX_MEETING_ROOM_PARTICIPANTS, ROOM_MAX_DIGITAL_OPEN_HOUSE_PARTICIPANTS, } from '../../constants';
import { useEffect } from 'react';
import Logger from 'js-logger';
import { ConferenceToolbar, ButtonTooltip } from '../../components/ConferenceToolbar';
import { ToolbarButton } from '../../components/ConferenceToolbar/ToolbarButton';
import styled from 'styled-components';
import { ConferenceVideo } from '../../components/ConferenceVideo';
import { observer } from 'mobx-react';
import { RecordingIndicator } from '../../components/RecordingIndicator';
import { ConferenceParticipants } from '../../components/ConferenceParticipants';
import { TiledConferenceVideos } from '../../components/TiledConferenceVideos';
import { Presentation } from '../../components/Presentation';
// import { UserMediaStreamTest } from '../../components/UserMediaStreamTest';
import { StillHereDialog } from '../../components/StillHereDialog';
import { forIn, values } from 'lodash-es';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDailycoVideoConference } from '../../hooks/useDailycoVideoConference';
import { useLrLiveExtension } from '../../hooks/useLrLiveExtension';
import { useStillHereTimer } from '../../hooks/useStillHereTimer';
import { useRemoteControl } from '../../scenes/RoomRemote';
import { decodeString } from '../../services/simple-cypher';
import { AgentApi, LrLiveApi, Pendo } from '../../api';
import { AgentBG } from './AgentBG';
import { GuestWelcomeModal } from './GuestWelcomeModal';
import { useStore } from '../../mobx-store';
import { useParticipantsName } from '../../hooks/useParticipantsName';
import { RoomDrawer } from './RoomDrawer';
import { RecordingConfirmSnackbar } from '../../components/RecordingConfirmSnackbar';
import { useDailycoConferenceDevices } from '../../hooks/useDailycoConferenceDevices';
import { AudioVideo } from '../../components/AudioVideo';
import { useCanJoinMeeting } from '../../hooks/useCanJoinMeeting';
import { AtCapacityModal } from './AtCapacityModal';

const { 
    REACT_APP_ENABLE_FAKE_PARTICIPANTS = false,
    REACT_APP_FAKE_PARTICIPANTS_HAVE_VIDEO = false,
} = process.env;
const logger = Logger.get('Room');
const RoomContainer = withTheme(styled(Box)`
    background: url(https://s3.amazonaws.com/media-p.slid.es/uploads/1302223/images/7256595/bg_housetile.png) no-repeat center center !important;
    background-size: cover !important;
    transition: ${({ theme, isUtilsDrawerOpen })=> (
        isUtilsDrawerOpen ?
        (
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })
        ) :
        (
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        )
    )};

    ${({ theme })=> theme.breakpoints.up('sm')} {
        ${({ isUtilsDrawerOpen })=> (!isUtilsDrawerOpen && ('margin-right: -' + ROOM_DRAWER_WIDTH + 'px;'))}
    }
`);
const ParticipantContainer = styled(AppBar)`
    background: rgba(18,181,234,0);
    // backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    z-index: 2;
`;
const VideosContainer = styled(DialogContent)`
    position: relative;
`;


export const Room = observer(function Room() {
    const { RoomStore, MessengerStore } = useStore();
    const location = useLocation();
    const {
        cmt = 60, // close modal timeout (minutes)
        kt = 5, // kick timeout (minutes after close modal)
        jwt = '',
    } = queryString.parse(location.search);
    const roomToken = useRef(jwt);
    const ROOM_TOKEN = roomToken.current;
    const queryParams = useParams();
    const { roomId: ROOM_ID } = queryParams;
    const history = useHistory();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const {
        extensionPopupOpened,
        userMediaStream,
        extensionInstalled,
        startExtensionScreenshare,
        stopExtensionScreenshare } = useLrLiveExtension();
    const {
        callObject,
        roomState,
        roomDetails,
        recordingState,
        recordingId,
        participants,
        screenShareParticipants,
        joinMeeting,
        leaveMeeting:_leaveMeeting,
        toggleVideo,
        toggleAudio,
        toggleScreenShare,
        togglePresenterScreenShare,
        toggleRecording,
        cycleCamera,
    } = useDailycoVideoConference({
        roomId: ROOM_ID,
        roomToken: ROOM_TOKEN,
        hasExtensionInstalled: extensionInstalled,
        startExtensionScreenshare,
        stopExtensionScreenshare 
    });
    const [agent, setAgent] = useState();
    const [lrLiveInfo, setLrLiveInfo] = useState(null);
    const [stillHereOpen, setStillHereOpen] = useState(false);
    const [audioParticipants, setAudioParticipants] = useState([]);
    const [videoParticipants, setVideoParticipants] = useState([]);
    const [allParticipantsExceptLocal, setAllParticipantsExceptLocal] = useState([]);
    const [presenterView, setPresenterView] = useState(false);
    const [slideshow, setSlideshow] = useState(null);
    const local = participants?.local;
    const {
        canJoinMeeting,
        hasCheckedCanJoinMeetingStatus,
    } = useCanJoinMeeting({
        maxParticipants: presenterView ? ROOM_MAX_DIGITAL_OPEN_HOUSE_PARTICIPANTS : ROOM_MAX_MEETING_ROOM_PARTICIPANTS,
        room: ROOM_ID,
    });
    const screenShareParticipant = values(screenShareParticipants)[0];
    const disableConfrenceToolbar = (!canJoinMeeting && !ROOM_TOKEN) || ![MEETING_IDLE, MEETING_JOINED, MEETING_ERROR].includes(roomState);
    const [allowRecording, setAllowRecording] = useState(false);
    const isConnected = !!callObject;
    const [communicationState, postMessage] = useBrowserContextCommunication('roomRemoteControl');
    const remoteWindowRef = useRef(null);
    const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
    const [isAtCapacityModalOpen, setIsAtCapacityModalOpen] = useState(false);
    const [isUtilsDrawerOpen, setIsUtilsDrawerOpen] = useState(false);
    const [utilsDrawerTabValue, setUtilsDrawerTabValue] = useState('chat');
    const [recordingConfirmOpen, setRecordingConfirmOpen] = useState(false);
    const [remoteFocused, setRemoteFocused] = useState(null);
    const { getParticipantsName } = useParticipantsName({
        userName: RoomStore?.user_name || local?.user_name,
        userId: local?.user_id,
        room: ROOM_ID,
    });
    const {
        videoDevices,
    } = useDailycoConferenceDevices(callObject);

    logger.debug('roomState', roomState);
    logger.debug('callObject', callObject);
    logger.debug('local', local);
    logger.debug('screenShareParticipant', screenShareParticipant);
    logger.debug('isUtilsDrawerOpen', isUtilsDrawerOpen);

    useRemoteControl({
        communicationState,
        postMessage,
        local,
        roomState,
        toggleVideo,
        toggleAudio,
        toggleScreenShare,
        disableConfrenceToolbar,
        togglePresenterView,
        addFakeParticipant,
        setRemoteFocused,
        leaveMeeting,
        historicalMessengerMessages: MessengerStore.messages,
    });
    

    // USE EFFECTS

    /**
     * Auto join meeting
     */
    useEffect(()=> {
        if(isConnected) return;

        if(ROOM_TOKEN || slideshow) {
            // if a guest joins
            if(slideshow && !ROOM_TOKEN) {
                RoomStore.user_name = 'Guest';
            }
            joinMeeting();
        }
    }, [slideshow]);

    /**
     * Manages when to show the guest modal and
     * the meeting at capacity modal.
     */
    useEffect(()=> {
        if(hasCheckedCanJoinMeetingStatus && !ROOM_TOKEN && !isConnected && !lrLiveInfo) {
            if(canJoinMeeting) {
                setIsGuestModalOpen(true);
            } else {
                setIsAtCapacityModalOpen(true);
            }
        } else {
            setIsGuestModalOpen(false);
            setIsAtCapacityModalOpen(false);
        }
    }, [hasCheckedCanJoinMeetingStatus, canJoinMeeting, isConnected, lrLiveInfo])

    /**
     * Remove token from the URL. This prevents
     * the user from accidently sharing it with 
     * someone else.
     */
    useEffect(()=> {
        if(ROOM_TOKEN && window.location.hostname !== "localhost") {
            delete queryParams.jwt;
            delete queryParams.roomId;

            const newParams = queryString.stringify(queryParams);
            const newLocation = `${location.pathname}${newParams.length ? `?${newParams}` : ''}`;
            
            history.replace(newLocation);
        }
    }, [ROOM_TOKEN]);

    /**
     * Recording State changes are handled here
     * If recording is on, throw an alert when the user resizes the window
     * If recording if finished and recordingId exists,
     * then ask the user if they want to discard the recording
     */
    useEffect(() => {
        function alertUserNoResize() {
            alert('Please avoid resizing the window during a recording');
        }

        if(local?.screenVideoTrack && recordingState === MEETING_EVENT_RECORDING_STARTED) {
            async function setListener() {
                // wait a bit before turning on the resize alert
                await Promise.delay(2000);
                window.addEventListener('resize', alertUserNoResize);
            }
            setListener();
        } else {
            window.removeEventListener('resize', alertUserNoResize);
        }
        return () => {
            window.removeEventListener('resize', alertUserNoResize);
        }
    }, [recordingState]);

    /**
     * If the recording state is turned off, show the keep recording confirmation
     */
    useEffect(() => {
        if (recordingState === MEETING_EVENT_RECORDING_STOPPED) {
            logger.debug('stopped recordingId', recordingId);
            if(recordingId)
                setRecordingConfirmOpen(true);
        }
        return () => {};
    }, [recordingState]);

    /*
     * Allow recording if owner and room allows recording
     */
    useEffect(() => {
        if (roomDetails?.config?.enable_recording) {
            setAllowRecording(local?.owner);
        }
    }, [local?.owner, roomDetails]);

    /**
     * OnLoading the ROOM_ID, fetch all relevant data
     * Fetch agents info/lrLiveInfo to display in the background
     */
    useEffect(()=> {
        if(ROOM_ID) {
            fetchAgentsInfo(decodeString(ROOM_ID));
            fetchLrLiveInfo(ROOM_ID);
        }
    }, [ROOM_ID]);

    /**
     * Presenter view opens/closes remote
     */
    useEffect(()=> {
        const titlePostfix = ' - Present Me 👈';
        setRemoteFocused(null);
        togglePresenterScreenShare(presenterView);
        if (allowRecording) toggleRecording(presenterView);

        if (!presenterView) {
            if(remoteWindowRef.current) {
                remoteWindowRef.current.close();
                remoteWindowRef.current = null;
            }
            document.title = document.title.replace(titlePostfix, '');
            return;
        }
        document.title = document.title + titlePostfix;
        const [windowHeight, windowWidth] = [600, 600];
        const [leftPos, topPos] = [window.screen.width - windowWidth, 0];
        const windowOptions = [
            `height=${windowHeight}`,
            `width=${windowWidth}`,
            `left=${leftPos}`,
            `top=${topPos}`,
            `status=yes`,
            `toolbar=no`,
            `menubar=no`,
            `location=no`].join(',');
        remoteWindowRef.current = window.open(
            `${window.location.protocol}//${window.location.host}/remote/${ROOM_ID}?jwt=${ROOM_TOKEN}`,
            null,
            windowOptions);
    }, [presenterView]);

    /**
     * If a userMediaStream was created or destroyed, toggle daily screenshare with
     * custom userMedia
     */
    useEffect(() => {
        if (!isConnected) return;
        if(!userMediaStream) {
            callObject.stopScreenShare();
        } else {
            logger.debug('sending extension mediaStream', userMediaStream);
            callObject.startScreenShare({ mediaStream: userMediaStream });
        }
        return () => {};
    }, [userMediaStream, isConnected]);

    /*
    if extensionPopupOpened before the presenterView, trigger presenter view
    */
   useEffect(() => {
       if (extensionPopupOpened && !presenterView) {
           togglePresenterView(true);
       }
       return () => {
       };
   }, [extensionPopupOpened])

    /**
     * Close presenter view when meeting is over
     */
    useEffect(()=> {
        if(roomState === MEETING_LEAVING) {
            setPresenterView(false);
        }
    }, [roomState])

    /**
     * Separate audio only and video participants
     */
    useEffect(()=> {
        if(!participants) return;

        const audioParticipants = [];
        const videoParticipants = [];
        const allParticipantsExceptLocal = [];
        
        forIn(participants, (participant, id)=> {
            if(id === 'local' && !participant.screen) return;

            participant.id = id;
            participant.user_name = participant.user_name || getParticipantsName(participant.user_id);

            if(id !== 'local') {
                allParticipantsExceptLocal.push(participant);
            }

            if (participant.video || participant.screen || (REACT_APP_ENABLE_FAKE_PARTICIPANTS === 'true' && REACT_APP_FAKE_PARTICIPANTS_HAVE_VIDEO === 'true')) {
                videoParticipants.push(participant);
            } else {
                audioParticipants.push(participant);
            }
        });

        setAudioParticipants(audioParticipants);
        setVideoParticipants(videoParticipants);
        setAllParticipantsExceptLocal(allParticipantsExceptLocal);
    }, [participants]);

    /**
     * Still here logic starts here
     * Display a dialog if the user is still here after specified amount of time
     */
    const kickTimeoutMins = parseInt(kt, 10);
    const kickTimer = useStillHereTimer(() => {
        history.push('/close');
    }, kickTimeoutMins * 60 * 1000, { lazy: true });

    const closeModalTimeoutMins = parseInt(cmt, 10);
    const closeModalTimer = useStillHereTimer(() => {
        setStillHereOpen(true);
        kickTimer.start();
    }, closeModalTimeoutMins * 60 * 1000);

    // if still here was closed by user, restart the timer
    const onStillHereClose = useCallback(() => {
        setStillHereOpen(false);
        logger.debug('User is still here, don\'t close the window, reset the kick timer');
        // clearTimeout(kickTimer.current);
        // startCloseTimer();
        kickTimer.stop();
        closeModalTimer.restart();
    }, [kickTimer, closeModalTimer]);

    /* end still here timer logic */

    // Functions
    function addFakeParticipant() {
        if (!isConnected) return;

        logger.debug('Adding fake participant');
        callObject.addFakeParticipant({ aspectRatio: '16/9' });
    }

    function togglePresenterView(status = undefined) {
        setIsUtilsDrawerOpen(false);
        setPresenterView(status !== undefined ? status : !presenterView);
    }

    async function fetchAgentsInfo(agentId) {
        try {
            const agent = await AgentApi.getAgentsInfo(agentId);
            Pendo.initialize(agent);
            setAgent(agent);
        } catch (err) {
            logger.error('Error above (^) fetching the agents info.');
        }
    }

    async function fetchLrLiveInfo(lrLiveId) {
        try {
            const data = await LrLiveApi.getLiveData(lrLiveId);
            const { links: { slideshow }, userId } = data;
            setLrLiveInfo(data);
            fetchAgentsInfo(userId);
            setSlideshow(slideshow);
        } catch (err) {
            logger.error('Error fetching liveData', err);
        }
    }

    function updateUserAndJoinMeeting(name) {
        RoomStore.user_name = name;
        setIsGuestModalOpen(false);
        
        if(!isConnected) joinMeeting();
    }

    function toggleChat() {
        if(utilsDrawerTabValue === 'chat' || !isUtilsDrawerOpen) {
            toggleDrawer();
        }

        setUtilsDrawerTabValue('chat');
    }

    function toggleDrawer() {
        setIsUtilsDrawerOpen(!isUtilsDrawerOpen);
    }

    function toggleSettings() {
        if(utilsDrawerTabValue === 'settings' || !isUtilsDrawerOpen) {
            toggleDrawer();
        }

        setUtilsDrawerTabValue('settings');
    }

    function onFocusRemoteClick() {
        if(remoteWindowRef.current) {
            remoteWindowRef.current.focus();
        }
        logger.debug('focus on remote');
    }

    function leaveMeeting() {
        setIsUtilsDrawerOpen(false);
        _leaveMeeting();

        if(!local?.owner) {
            if(lrLiveInfo?.links?.post_roll) {
                window.location = lrLiveInfo.links.post_roll;
            } else {
                history.push('/close');
            }
        }
    }
 
    return (
        <>
            {agent && (
                <>
                    <GuestWelcomeModal 
                        fullScreen={isXs}
                        hideCloseButton
                        open={isGuestModalOpen} 
                        agent={agent}
                        onJoinMeeting={updateUserAndJoinMeeting}
                    />

                    <AtCapacityModal
                        fullScreen={isXs}
                        hideCloseButton
                        agent={agent}
                        open={isAtCapacityModalOpen}
                    />
                </>
            )}

            {/* 
                Screen Share - Participants Audio tracks 

                This will allow everyone to hear everyone.
            */}
            {screenShareParticipant && allParticipantsExceptLocal.map((participant)=> (
                <AudioVideo audioTrack={participant.audioTrack} />
            ))}
            {/* END Screen Share - Participants Audio tracks */}

            <Box
                className="Room"
                width="100%" 
                height="100%"
                display="flex"
            >
                <RoomContainer 
                    className="Room-RoomContainer"
                    isUtilsDrawerOpen={isUtilsDrawerOpen}
                    position="relative"
                    flexGrow={1}
                    overflow="hidden"
                >
                    {/* Room Recording indicator */}
                    {recordingState === MEETING_EVENT_RECORDING_STARTED && (
                        <RecordingIndicator />
                    )}
                    
                    {/* non-presenter view */}
                    {!presenterView && (
                        <Box 
                            className="Room-NonPresenterContainer"
                            height="100%"
                        >
                            {/* Agent Background info */}
                            {agent && (
                                <Box
                                    className="Room-NonPresenterContainer-AgentBG"
                                    position="absolute"
                                    top="0"
                                    bottom="0"
                                    right="0"
                                    left="0"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems={isXs ? 'flex-start' : 'center'}
                                    pt={isXs && 6}
                                >
                                    <AgentBG agent={agent} lrLive={lrLiveInfo} />
                                </Box>
                            )}

                            <Box
                                className="Room-NonPresenterContainer-Content"
                                width="100%"
                                height="100%"
                                display="flex"
                                flexDirection="column"
                            >
                                {!screenShareParticipant && participants && audioParticipants?.length > 0 && (
                                    <ParticipantContainer
                                        className="Room-NonPresenterContainer-Content-ParticipantContainer"
                                        component={Box}
                                        position="static"
                                        color="transparent"
                                        elevation={0}
                                        pt={1}
                                    >
                                        <ConferenceParticipants
                                            mx="auto"
                                            participants={audioParticipants}
                                        />
                                    </ParticipantContainer>
                                )}

                                <VideosContainer className="Room-NonPresenterContainer-Content-VideosContainer">
                                    {isConnected && !screenShareParticipant && (
                                        <TiledConferenceVideos
                                            className="Room-NonPresenterContainer-Content-VideosContainer-TiledConferenceVideos"
                                            participants={videoParticipants}
                                        />
                                    )}

                                    {screenShareParticipant && (
                                        <ConferenceVideo
                                            className="Room-NonPresenterContainer-Content-VideosContainer-ConferenceVideo"
                                            videoTrack={screenShareParticipant?.screenVideoTrack}
                                            isLoading={screenShareParticipant?.isLoading}
                                            isScreenShare
                                            maxWidth="80%"
                                            mx="auto"
                                        />
                                    )}
                                </VideosContainer>


                                {local && isConnected && (
                                    <Box
                                        className="Room-NonPresenterContainer-Content-LocalUsersVideo"
                                        position="absolute"
                                        bottom={isXs || (isSm && isUtilsDrawerOpen) ? '100px' : 0}
                                        left="0"
                                        pb={!isXs && 3}
                                        pl={isXs ? 1 : 3}
                                        style={{
                                            transition: (
                                                theme.transitions.create('bottom', {
                                                    easing: theme.transitions.easing.easeOut,
                                                    duration: theme.transitions.duration.enteringScreen,
                                                })
                                            )
                                        }}
                                    >
                                        <ConferenceVideo
                                            videoTrack={local?.videoTrack}
                                            audioTrack={local?.audioTrack}
                                            isLoading={local?.isLoading}
                                            isLocalUser
                                            width="150px"
                                            border="1px solid #ccc"
                                            borderRadius="4px"
                                            overflow="hidden"
                                        />
                                    </Box>
                                )}                    

                                <Box position="absolute" bottom="0" left="0" right="0" bgcolor="rgba(255,255,255,0)">
                                    <ConferenceToolbar
                                        allowBtnAnimations
                                        allowRecording={false} // set this to allowRecording if we want to enable the recording button
                                        recordingState={recordingState}
                                        slideshow={slideshow}
                                        roomState={roomState}
                                        isOwner={local?.owner}
                                        videoEnabled={local?.video}
                                        audioEnabled={local?.audio}
                                        totalVideoDevices={videoDevices?.length ?? 0}
                                        screenShareEnabled={local?.screenVideoTrack}
                                        isSomeoneElseScreenSharing={!!(screenShareParticipant && screenShareParticipant.user_id !== local.user_id)}
                                        screenShareParticipant={screenShareParticipant}
                                        onToggleVideoClick={toggleVideo}
                                        onToggleAudioClick={toggleAudio}
                                        onToggleScreenShareClick={toggleScreenShare}
                                        onStartMeetingClick={joinMeeting}
                                        onEndMeetingClick={leaveMeeting}
                                        onPresenterViewClick={togglePresenterView}
                                        onCycleCameraClick={cycleCamera}
                                        onToggleChatClick={toggleChat}
                                        onSettingsClick={toggleSettings}
                                        disabled={disableConfrenceToolbar}

                                        onAddFakeUserClick={addFakeParticipant}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {/* end non-presenter view */}

                    {/* start presenter view */}
                    {presenterView && (
                        <Box
                            className="Room-PresenterContainer"
                            width="100%"
                            height="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            {slideshow && (
                                <Presentation src={slideshow} />
                            )}

                            {local && isConnected && local?.videoTrack && (
                                <Box
                                    position="absolute"
                                    bottom={isXs ? '100px' : 0}
                                    left="0"
                                    pb={!isXs && 3}
                                    pl={isXs ? 1 : 3}
                                >
                                    <ConferenceVideo
                                        videoTrack={local?.videoTrack}
                                        audioTrack={local?.audioTrack}
                                        isLoading={local?.isLoading}
                                        isLocalUser
                                        width="250px"
                                        border="1px solid #ccc"
                                        borderRadius="4px"
                                        overflow="hidden"
                                    />
                                </Box>
                            )}

                            {remoteFocused === false && (
                                <Box
                                    position="absolute"
                                    bottom="0"
                                    left="0"
                                    right="0"
                                    bgcolor="rgba(255,255,255,0)"
                                    padding="20px"
                                    width="10%"
                                    style={{
                                        opacity: '0.4',
                                    }}
                                >
                                    <ButtonTooltip title="Show Companion">
                                        <ToolbarButton
                                            color="secondary"
                                            disabled={disableConfrenceToolbar}
                                            onClick={onFocusRemoteClick}
                                        >
                                            <SettingsRemoteIcon />
                                        </ToolbarButton>
                                    </ButtonTooltip>
                                </Box>
                            )}
                        </Box>
                    )}
                    {/* end presenter view */}


                    <RecordingConfirmSnackbar
                        open={recordingConfirmOpen}
                        roomId={ROOM_ID}
                        recordingId={recordingId}
                        jwt={ROOM_TOKEN}
                        handleClose={()=> setRecordingConfirmOpen(false)}
                    />
                </RoomContainer>

                <Box width={!isXs && `${ROOM_DRAWER_WIDTH}px`}>
                    {local && isConnected && (
                        <RoomDrawer 
                            callObject={callObject}
                            localUser={local}
                            roomId={ROOM_ID}
                            tabValue={utilsDrawerTabValue}
                            userName={local?.user_name || RoomStore.user_name}
                            userId={local?.user_id}
                            open={isUtilsDrawerOpen}
                            onTabClick={setUtilsDrawerTabValue}
                            onToggleDrawer={toggleDrawer}
                        />
                    )}
                </Box>

                <StillHereDialog open={stillHereOpen} onClose={onStillHereClose} ></StillHereDialog>
            </Box>
        </>
    );
})
