import Logger from 'js-logger';
import Promise from 'bluebird';
import { useEffect, useState } from 'react';
import {
    MEETING_IDLE,
    MEETING_JOINING,
    MEETING_LEAVING,
    MEETING_EVENT_JOINED_MEETING,
    MEETING_EVENT_LEFT_MEETING,
    MEETING_EVENT_ERROR,
    MEETING_JOINED,
    MEETING_ERROR,
    MEETING_EVENT_RECORDING_STARTED,
    MEETING_EVENT_RECORDING_STOPPED,
    MEETING_EVENT_RECORDING_STATS,
    MEETING_EVENT_RECORDING_ERROR,
    MEETING_EVENT_RECORDING_UPLOAD_COMPLETED,
    MEETING_EVENT_APP_MESSAGE_RECORDING_EVENT,
} from '../../constants';
import DailyIframe from '@daily-co/daily-js';
import { forIn } from 'lodash-es';

const logger = Logger.get('useDailycoVideoConference');
const DEFAULT_PARTICIPANTS = {
    local: {
        isLoading: true,
    }
};

export function useDailycoVideoConference({
    roomId,
    roomToken,
    hasExtensionInstalled = false,
    startExtensionScreenshare = ()=> {},
    stopExtensionScreenshare = ()=> {},
}) {
    const DAILYCO_URL = `https://listreports.daily.co/${roomId}`;
    const [callObject, setCallObject] = useState(null);
    const [roomState, setRoomState] = useState(MEETING_IDLE);
    const [roomDetails, setRoomDetails] = useState(null);
    const [participants, setParticipants] = useState(DEFAULT_PARTICIPANTS);
    const [screenShareParticipants, setScreenShareParticipants] = useState({});
    const [recordingState, setRecordingState] = useState(null);
    const [recordingId, setRecordingId] = useState(null);
    const [isLocalRecording, setIsLocalRecording] = useState(false);
    const [error, setError] = useState(null);

    useEffect(()=> {
        if(!callObject) return;

        const cleanupFuncs = [];

        // Meeting State Changes
        cleanupFuncs.push(
            handleMeetingStateChanges({
                callObject,
                setRoomState,
                setCallObject,
                setParticipants,
            })
        );

        // Participant State Changes
        cleanupFuncs.push(
            handleParticipantStateChanges({
                recordingState,
                callObject,
                setParticipants,
                setScreenShareParticipants,
            })
        );

        // Recording State Changes
        cleanupFuncs.push(
            handleRecordingStateChanges({
                callObject,
                setRecordingState,
                setRecordingId,
            })
        );

        // Custom App Message Handlers
        cleanupFuncs.push(
            handleAppMessages({
                callObject,
                setRecordingState,
            })
        );

        // Camera Error Listener
        cleanupFuncs.push(
            handleErrors({
                callObject,
                setError,
            })
        );

        return function () {
            cleanupFuncs.forEach((func)=> func());
        }
    }, [callObject]);


    /* watch for new participants and recordingState so we can send new participants
    notifications on current recording state
    */
    useEffect(() => {
        if(!callObject) return;
        callObject.on('participant-joined', handleNewParticipantRecordingNotification);
        return () => {
            callObject.off('participant-joined', handleNewParticipantRecordingNotification);
        };

        async function handleNewParticipantRecordingNotification(e) {
            logger.debug('handling new participant notification to', e, 'recordingState', recordingState);
            // wait 5 second before notifying the participant to ensure they've connected to the message socket
            await Promise.delay(5000);
            if(recordingState === MEETING_EVENT_RECORDING_STARTED && isLocalRecording) {
                callObject.sendAppMessage({
                    e: MEETING_EVENT_APP_MESSAGE_RECORDING_EVENT,
                    p: { action: MEETING_EVENT_RECORDING_STARTED },
                }, e?.participant?.session_id);
                logger.debug('new participant recording notification sent to', e?.participant?.session_id);
            }
        }

    }, [recordingState, callObject, isLocalRecording])

    // Functions
    async function joinMeeting() {
        const newCallObject = DailyIframe.createCallObject();
        window.dailyCallObject = newCallObject;
        setCallObject(newCallObject);
        setRoomState(MEETING_JOINING);

        try {
            await newCallObject.join({ 
                url: DAILYCO_URL, 
                token: roomToken,
            });
            // get the room details from the newCallObject
            const response = await newCallObject.room();
            logger.debug('joined room details', response);
            setRoomDetails(response);
        } catch (err) {
            logger.error('[useDailycoVideoConference][joinMeeting]', err);
        }
    }

    function leaveMeeting() {
        if(!callObject) return;

        setRoomState(MEETING_LEAVING);
        callObject.leave();
    }

    function toggleVideo() {
        if(!callObject) return;
        
        callObject.setLocalVideo(!participants?.local?.video);
    }

    function toggleAudio() {
        if(!callObject) return;

        callObject.setLocalAudio(!participants?.local?.audio);
    }

    async function toggleScreenShare() {
        if(!callObject) return;

        // if a status was passed in, use it treat false as stop and true as start
        (participants?.local?.screen)
            ? callObject.stopScreenShare()
            : callObject.startScreenShare();
    }

    async function togglePresenterScreenShare(status = undefined) {
        if (!callObject) return;

        if (hasExtensionInstalled) {
            try {
                const promise = (participants?.local?.screen || status === false)
                    ? stopExtensionScreenshare()
                    : startExtensionScreenshare();
                const result = await promise;
                if (result) return;
            } catch (err) {
                logger.error('Error toggling extension screenshare', err);
            }
        }

        // if a status was passed in, use it treat false as stop and true as start
        (participants?.local?.screen || status === false)
            ? callObject.stopScreenShare()
            : callObject.startScreenShare();

    }

    async function toggleRecording(status = undefined) {
        if(!callObject) return;
        logger.debug('toggleRecording click', recordingState);
        if (recordingState === MEETING_EVENT_RECORDING_STARTED || status === false) {
            callObject.stopRecording();
            setIsLocalRecording(false);
        } else {
            callObject.startRecording();
            setIsLocalRecording(true);
        }
    }

    function cycleCamera() {
        if(!callObject) return;

        callObject.cycleCamera();
    }

    // Return
    return {
        // Vars
        callObject,
        roomState,
        recordingState,
        recordingId,
        participants,
        screenShareParticipants,
        roomDetails,
        error,

        // Functions
        joinMeeting,
        leaveMeeting,
        toggleVideo,
        toggleAudio,
        toggleScreenShare,
        togglePresenterScreenShare,
        toggleRecording,
        cycleCamera,
    };
}

function handleMeetingStateChanges({
    callObject,
    setRoomState,
    setCallObject,
    setParticipants,
} = {}) {
    if(!callObject) throw new Error('callObject is required');
    if(!setRoomState) throw new Error('setRoomState is required');
    if(!setCallObject) throw new Error('setCallObject is required');
    if(!setParticipants) throw new Error('setParticipants is required');

    const events = [
        MEETING_EVENT_JOINED_MEETING,
        MEETING_EVENT_LEFT_MEETING,
        MEETING_EVENT_ERROR,
    ];

    // Update the meeting state
    handleMeetingState();

    // Set event listeners
    events.forEach((event)=> {
        callObject.on(event, handleMeetingState);
    });

    // Component did unmount function
    return function cleanup() {
        // Unset event listeners
        events.forEach((event)=> {
            callObject.off(event, handleMeetingState);
        });
    }

    // Functions
    async function handleMeetingState(e) {
        logger.debug('handleMeetingState', 'event', e);

        switch(callObject.meetingState()) {
            case MEETING_EVENT_JOINED_MEETING:
                setRoomState(MEETING_JOINED);
                break;
            case MEETING_EVENT_LEFT_MEETING:
                try {
                    await callObject.destroy();
                } catch (err) {
                    logger.error(err);
                    throw err;
                }
                
                setCallObject(null);
                setParticipants(DEFAULT_PARTICIPANTS);
                setRoomState(MEETING_IDLE);
                break;
            case MEETING_EVENT_ERROR:
                setRoomState(MEETING_ERROR);
                break;
            default:
                break;
        }
    }
}

function handleParticipantStateChanges({
    callObject,
    setParticipants,
    setScreenShareParticipants,
    recordingState,
}) {
    if(!callObject) throw new Error('callObject is required');
    if(!setParticipants) throw new Error('setParticipants is required');
    if(!setScreenShareParticipants) throw new Error('setScreenShareParticipants is required');

    const events = [
        "participant-joined",
        "participant-updated",
        "participant-left"
    ];

    updateParticipants();

    // Set event listeners
    events.forEach((event)=> {
        callObject.on(event, updateParticipants);
    });

    // Component did unmount function
    return function cleanup() {
        // Unset event listeners
        events.forEach((event)=> {
            callObject.off(event, updateParticipants);
        });
    }

    // Functions
    function updateParticipants() {
        const participants = {};
        const screenShareParticipants = {};

        forIn(callObject.participants(), (participant, id) => {
            participant.id = id;

            participants[id] = participant;

            if(participant.screen) screenShareParticipants[id] = participant;
        })

        setParticipants(participants);
        setScreenShareParticipants(screenShareParticipants);
    }
}


function handleRecordingStateChanges({
    callObject,
    setRecordingState,
    setRecordingId,
}) {
    if (!callObject) throw new Error('callObject is required');
    if (!setRecordingState) throw new Error('setRecordingState is required');
    if (!setRecordingId) throw new Error('setRecordingId is required');

    const events = [
        MEETING_EVENT_RECORDING_STARTED,
        MEETING_EVENT_RECORDING_STOPPED,
        MEETING_EVENT_RECORDING_STATS,
        MEETING_EVENT_RECORDING_ERROR,
        MEETING_EVENT_RECORDING_UPLOAD_COMPLETED,
    ];

    // Set event listeners
    events.forEach((event) => {
        callObject.on(event, handleRecordingEvent);
    });

    // Component did unmount function
    return function cleanup() {
        // Unset event listeners
        events.forEach((event) => {
            callObject.off(event, handleRecordingEvent);
        });
    }

    function handleRecordingEvent(e) {
        logger.debug('Recording Event', JSON.stringify(e, null, 2));
        switch (e.action) {
            case MEETING_EVENT_RECORDING_STARTED:
                const { recordingId } = e;
                if (recordingId) setRecordingId(recordingId);
                // falls through
            case MEETING_EVENT_RECORDING_STOPPED:
                callObject.sendAppMessage({ e: MEETING_EVENT_APP_MESSAGE_RECORDING_EVENT, p: e }, '*');
                setRecordingState(e.action);
                break;
            case MEETING_EVENT_RECORDING_ERROR:
            case MEETING_EVENT_RECORDING_UPLOAD_COMPLETED:
                setRecordingState(e.action);
                break;
            case MEETING_EVENT_RECORDING_STATS:
                logger.debug('recording stats', e);
                break;
            default:
                break;
        }
    }

    
}

function handleErrors({
    callObject,
    setError,
}) {
    if(!callObject) throw new Error('callObject is required');
    if(!setError) throw new Error('setError is required');

    const events = [
        "camera-error",
        "error",
    ];

    // Set event listeners
    events.forEach((event)=> {
        callObject.on(event, handleErrorEvent);
    });

    // Component did unmount function
    return function cleanup() {
        // Unset event listeners
        events.forEach((event)=> {
            callObject.off(event, handleErrorEvent);
        });
    }

    // Functions
    function handleErrorEvent(err) {
        logger.error('[Camera Error]', err);
    }
}




function handleAppMessages({
    callObject,
    setRecordingState,
}) {
    if(!callObject) throw new Error('callObject is required');
    if (!setRecordingState) throw new Error('callObject is required');

    callObject.on('app-message', handleAppMessage)


    function handleAppMessage(m) {
        logger.debug('new app-message', m);
        const { data: { e, p } } = m;
        switch(e) {
            case MEETING_EVENT_APP_MESSAGE_RECORDING_EVENT:
                handleRecordingEvent(p);
                break;
            default:
                logger.warn('Unhandled app message', m)
                break;
        }
    }


    function handleRecordingEvent(e) {
        logger.debug('Custom Recording Event for non-hosts', e);
        switch (e.action) {
            case MEETING_EVENT_RECORDING_STARTED:
            case MEETING_EVENT_RECORDING_STOPPED:
                setRecordingState(e.action);
                break;
            case MEETING_EVENT_RECORDING_STATS:
                logger.debug('recording stats', e);
                break;
            default:
                break;
        }
    }


    // Component did unmount function
    return function cleanup() {
        // Unset event listeners
        callObject.off('message', handleAppMessage);
    }
}
