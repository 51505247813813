import React, { useRef, useEffect, forwardRef } from "react";
import Logger from 'js-logger';

const logger = Logger.get('AudioVideo');

const Video = forwardRef(function Video({ ...props }, ref) {
    return (
        <video 
            ref={ref}
            autoPlay 
            muted 
            playsInline
            {...props}
        />
    );
});

const Audio = forwardRef(function Audio({ ...props }, ref) {
    return (
        <audio 
            ref={ref}
            autoPlay 
            playsInline
            {...props}
        />
    );
});

export function AudioVideo({
    videoTrack,
    audioTrack,
    videoRef,
    audioRef,
    ...props
}) {
    const videoEl = useRef(null);
    const audioEl = useRef(null);

    /**
     * Updated the video stream object
     */
    useEffect(()=> {
        videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]));
    }, [videoTrack]);

    /**
     * Updates the audio stream object
     */
    useEffect(()=> {
        audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
    }, [audioTrack]);

    /**
     * Syncs the local video ref with a ref passed as a prop.
     * This will make sure they stay in sync.
     */
    useEffect(()=> {
        if(videoRef) {
            videoRef.current = videoEl.current;
        }
    }, [videoEl?.current]);

    /**
     * Syncs the local audio ref with a ref passed as a prop.
     * This will make sure they stay in sync.
     */
    useEffect(()=> {
        if(audioRef) {
            audioRef.current = audioEl.current;
        }
    }, [audioEl?.current]);

    return (
        <>
            {videoTrack && (
                <Video 
                    ref={videoEl}
                    {...props}
                />
            )}
            
            {audioTrack && (
                <Audio 
                    ref={audioEl}
                />
            )}
        </>
    )
}
