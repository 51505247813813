import { useState, useEffect } from "react";
import { REALTIME_AUTH_HEADER } from "../../constants";
import querystring from 'querystring';

const { REACT_APP_CHAT_SOCKET_URL } = process.env;

export function useRealtimeUrl({
    userName,
    userId,
    room,
    authorization = REALTIME_AUTH_HEADER
}) {
    const [url, setUrl] = useState(createUrl(room, userId, userName, authorization));

    useEffect(()=> {
        if(userName && userId && room) {
            const newUrl = createUrl(room, userId, userName, authorization);
            if(newUrl !== url) setUrl(newUrl);
        }
    }, [userName, userId, room, authorization]);

    return {
        url
    };
}

function createUrl(room, userId, userName, authorization) {
    if(!(room && userId && userName)) return null;

    const q = querystring.encode({
        room,
        userId,
        userName,
        Authorization: authorization,
    });
    return `${REACT_APP_CHAT_SOCKET_URL}/?${q}`;
}
