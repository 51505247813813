// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Grid, InputBase, Fade } from '@material-ui/core';
import LRButton from '../LRButton';
import { useTheme } from '@material-ui/core/styles';
import AnimatedScroll from '../../services/animated-scroll';
import { Message } from './Message';
import { useChat } from '../Chat';
import { random, filter, uniqBy, orderBy } from 'lodash-es';
import { REALTIME_CHAT_STATUS_OPEN } from '../../constants';
import { useStore } from '../../mobx-store';

const logger = Logger.get('Messenger');
const Container = styled(Box)`
    height: 100%;
    background-color: #eee;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export function Messenger({ 
    userId,
    userName,
    roomId,
    additionalMessages = [],
    ...props 
}) {
    const { MessengerStore } = useStore();
    const theme = useTheme();
    const scrollContainerRef = useRef();
    const {
        messages,
        sendMessage,
        connectionStatus,
        createMessageFromRawMessage,
    } = useMessenger(roomId, userId, userName, additionalMessages);
    const [displayMessages, setDisplayMessages] = useState([]);
    const [message, setMessage] = useState('');
    const showMessages = connectionStatus === REALTIME_CHAT_STATUS_OPEN;

    // UseEffects

    useEffect(()=> {
        setDisplayMessages(messages);
        MessengerStore.messages = messages;
    }, [messages]);

    /**
     * When messages changes scroll to the bottom
     */
    useEffect(()=> {
        scrollToBottom();
    }, [displayMessages?.length]);

    // Functions
    async function handleOnSubmit(e) {
        if(e) e.preventDefault();

        if(message) {
            const trimmedMessage = message.trim();
            const newMessage = { action: 'tempMessage', content: trimmedMessage, createdAt: new Date().toISOString() };
            const rawMessage = {
                e: 'message',
                p: {
                    createdAt: newMessage.createdAt,
                    content: newMessage.content,
                    room: roomId,
                    userId: userId,
                    userName: userName,
                    isLocalUser: true,
                },
            }
            
            sendMessage(newMessage);
            setDisplayMessages([].concat([], displayMessages, [createMessageFromRawMessage(rawMessage)]));
            setMessage('');
        }
    }

    function handleOnKeyDown(e) {
        if(e.keyCode !== 13 || (e.keyCode === 13 && e.shiftKey)) return;

        e.preventDefault();
        handleOnSubmit();
    }

    function scrollToBottom(duration = 400) {
        if(scrollContainerRef.current) {
            if(duration > 0) {
                const scroll = new AnimatedScroll(scrollContainerRef.current);
                scroll.top(scrollContainerRef.current.scrollHeight, duration);
            } else {
                scrollContainerRef.current.style.overflow = 'hidden';
                scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;

                /**
                 * A delay is needed in order for the scroll bar on a
                 * mac to be hidden and not stuck visible.
                 */
                setTimeout(()=> {
                    scrollContainerRef.current.style.overflow = null;
                }, 10);
            }
        }
    }

    return connectionStatus === REALTIME_CHAT_STATUS_OPEN && (
        <Container {...props}>
            <Box ref={scrollContainerRef} flexGrow="1" position="relative" maxHeight="100%" overflow="auto" pb={3}>
                {showMessages && (
                    <Fade in={true}>
                        <Box>
                            {displayMessages.map((message, i)=> {
                                return (
                                    <Message 
                                        className="message" 
                                        key={message.id} 
                                        message={message} 
                                        previousMessage={displayMessages[i - 1]}
                                        nextMessage={displayMessages[i + 1]}
                                    />
                                );
                            })}
                        </Box>
                    </Fade>
                )}
            </Box>

            <Box flexShrink="0">
                <form onSubmit={handleOnSubmit}>
                    <Box bgcolor="#fff" borderTop="1px solid #ddd">
                        <Grid
                            container
                            wrap="nowrap"
                            alignItems="flex-end"
                            justify="space-between"
                            style={{ height: '100%', padding: '16px 8px 16px 16px' }}
                        >
                            <InputBase
                                value={message}
                                multiline
                                type="text"
                                name="message"
                                fullWidth
                                placeholder="Type a message..."
                                required
                                autoComplete="off"
                                style={{ fontSize: '1rem', lineHeight: '1.7' }}
                                onKeyDown={handleOnKeyDown}
                                onChange={(e)=> (setMessage(e?.currentTarget?.value))}
                            />

                            <LRButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: theme.spacing(2) }}
                            >
                                Send
                            </LRButton>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </Container>
    );
}


function useMessenger(roomId, userId, userName, additionalMessages = []) {
    const {
        messageHistory,
        sendMessage,
        connectionStatus,
    } = useChat(userName, roomId, userId);
    const [messages, setMessages] = useState([]);

    useEffect(()=> {
        logger.debug('additionalMessages', additionalMessages);
        logger.debug('messageHistory', messageHistory);
        let messages = filter(messageHistory.map(createMessageFromRawMessage), { type: 'message' });
        messages = orderBy(uniqBy([].concat(additionalMessages, messages), 'id'), ['createdAt'], ['asc']);
        logger.debug('messages', messages);

        setMessages(messages);
    }, [messageHistory]);

    function createMessageFromRawMessage({ e:type, p:msg }) {
        const message = {
            id: msg?.id || `MSG-${random(100, 999999999)}`,
            createdAt: msg?.createdAt,
            type,
            content: {
                message: msg?.content,
            },
            room: msg?.room,
            userId: msg?.userId,
            userName: msg?.userName,
            isLocalUser: msg?.userId === userId,
        };

        return message;
    }
    
    return {
        messages,
        sendMessage,
        connectionStatus,
        createMessageFromRawMessage,
    }
}
