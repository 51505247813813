// COLORS
export const COLOR_RED = '#d51d4c';
export const COLOR_RED_LIGHT = '#ff2450';
export const COLOR_RED_DARK = '#C21A45';
export const COLOR_GREEN = '#8CB63F';
export const COLOR_BLUE = '#0ebdc8';
export const COLOR_BLUE_LIGHT = '#5bdce7';
export const COLOR_BLUE_DARK = '#0C949D';
export const COLOR_PRIMARY = COLOR_BLUE;
export const COLOR_PRIMARY_LIGHT = COLOR_BLUE_LIGHT;
export const COLOR_PRIMARY_DARK = COLOR_BLUE_DARK;
export const COLOR_SECONDARY = 'rgba(0, 0, 0, .4)';
export const COLOR_SECONDARY_LIGHT = 'rgba(255, 255, 255, .3)';
export const COLOR_SECONDARY_DARK = 'rgba(0, 0, 0, .7)';

// MEETING STATES
export const MEETING_IDLE = 'MEETING_IDLE';
export const MEETING_CREATING = 'MEETING_CREATING';
export const MEETING_JOINING = 'MEETING_JOINING';
export const MEETING_JOINED = 'MEETING_JOINED';
export const MEETING_LEAVING = 'MEETING_LEAVING';
export const MEETING_ERROR = 'MEETING_ERROR';

// MEETING EVENTS
export const MEETING_EVENT_JOINED_MEETING = 'joined-meeting';
export const MEETING_EVENT_LEFT_MEETING = 'left-meeting';
export const MEETING_EVENT_ERROR = 'error';
export const MEETING_EVENT_APP_MESSAGE = 'app-message';
export const MEETING_EVENT_STATE_UPDATED = 'meeting-state-updated'
export const MEETING_EVENT_DISABLE_TOOLBAR_UPDATED = 'disable-toolbar-updated';
export const MEETING_EVENT_LOCAL_PARTICIPANT_UPDATED = 'local-participant-updated';
export const MEETING_EVENT_REMOTE_INIT = 'meeting-remote-init';
export const MEETING_EVENT_RECORDING_STARTED = 'recording-started';
export const MEETING_EVENT_RECORDING_STOPPED = 'recording-stopped';
export const MEETING_EVENT_RECORDING_STATS = 'recording-stats';
export const MEETING_EVENT_RECORDING_ERROR = 'recording-error';
export const MEETING_EVENT_RECORDING_UPLOAD_COMPLETED = 'recording-upload-completed';
export const MEETING_EVENT_APP_MESSAGE_RECORDING_EVENT = 'app-message-recording-event'


// REMOTE EVENTS
export const REMOTE_EVENT_TOGGLE_VIDEO = 'remote-toggle-video';
export const REMOTE_EVENT_TOGGLE_AUDIO = 'remote-toggle-audio';
export const REMOTE_EVENT_TOGGLE_SCREENSHARE = 'remote-toggle-screenshare';
export const REMOTE_EVENT_CLOSE_PRESENTER_VIEW = 'remote-toggle-presenter-view';
export const REMOTE_EVENT_LEAVE_MEETING = 'remote-leave-meeting';
export const REMOTE_EVENT_LOADED = 'remote-loaded';
export const REMOTE_EVENT_CLOSED = 'remote-closed';
export const REMOTE_EVENT_ADD_FAKE_PARTICIPANT = 'remote-add-fake-participant';
export const REMOTE_EVENT_ON_FOCUS = 'remote-on-focus';
export const REMOTE_EVENT_ON_BLUR = 'remote-on-blur';

// REMOTE SCREENSHARE STATES
export const REMOTE_STATE_SCREENSHARE_NOT_STARTED = 'remote-screenshare-not-started';
export const REMOTE_STATE_SCREENSHARE_STARTED = 'remote-screenshare-started';
export const REMOTE_STATE_SCREENSHARE_STOPPED = 'remote-screenshare-stopped';

// EXTENSION EVENTS
export const EXT_EVENT_START_SCREENSHARE = 'SCREENSHARE_UI_REQUEST';
export const EXT_EVENT_STOP_SCREENSHARE = 'SCREENSHARE_UI_CANCEL';


// REALTIME
/**
 * This can be anything because its not validate on
 * the backend but it must be consistant everywhere in
 * the app.
 */
export const REALTIME_AUTH_HEADER = 'ListReports Auth'
export const REALTIME_JOINED = 'joined';
export const REALTIME_LEFT = 'left';
export const REALTIME_MESSAGE = 'message';
export const REALTIME_CHAT_STATUS_OPEN = 'Open';
export const REALTIME_PARTICIPANT_UPDATED = 'participant_updated';
export const REALTIME_HISTORY = 'history';
export const REALTIME_ACTION_HISTORY = 'history';
export const REALTIME_CONNECTION_INFO = 'connection_info';
export const REALTIME_ACTION_CONNECTION_INFO = 'connectionInfo';

// Room
export const ROOM_DRAWER_WIDTH = 375;
export const ROOM_MAX_MEETING_ROOM_PARTICIPANTS = 10;
export const ROOM_MAX_DIGITAL_OPEN_HOUSE_PARTICIPANTS = 10;
