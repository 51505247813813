import React from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { COLOR_BLUE, COLOR_BLUE_DARK } from '../../constants';
import Logger from 'js-logger';

const logger = Logger.get('LRAvatar');

const StyledAvatar = styled(Avatar)`
    ${({ size })=>
        (size === 'xsmall' && 'width:24px;height:24px;font-size:0.625rem') ||
        (size === 'small' && 'width:35px;height:35px;font-size:1rem') ||
        (size === 'medium' && 'width: 48px; height: 48px; font-size: 1.25rem') ||
        (size === 'large' && 'width:80px;height:80px;font-size:1.5rem') ||
        'width:50px;height:50px;font-size:1.25rem'};

    ${({ src, darken })=>
        darken === 'true' && src ? 'background-color: #000;' : `background-color: ${COLOR_BLUE}; color: #fff;`}

    &:hover {
        ${({ src, darken })=>
        darken === 'true' && src
            ? `img {
                        opacity: 0.8;
                    }; cursor:pointer;`
            : darken === 'true' && !src
                ? `background-color: ${COLOR_BLUE_DARK}; color:#fff;cursor:pointer;`
                : ''}
    }
`;

export function LRAvatar({ 
    name, 
    headshot,
    darken, 
    size = 'small', 
    ...props 
}) {
    const splitName = name?.split(' ', 2) || [];
    const firstName = splitName[0];
    const lastName = splitName[1];
    const initials = `${firstName?.substr(0, 1)}${lastName?.substr(0, 1) || ''}`.trim();

    return (
        <StyledAvatar size={size} src={headshot} darken={darken} {...props}>
            {initials}
        </StyledAvatar>
    );
}
LRAvatar.propTypes = {
    user: PropTypes.object,
    darken: PropTypes.string,
};
