import { useRealtimeUrl } from "../useRealtimeUrl";
import { useMemo } from "react";
import useWebSocket from "react-use-websocket";
import Logger from "js-logger";

const logger = Logger.get('useRealtime');

export function useRealtime({
    userName,
    userId,
    room,
    onOpen = ()=> {},
}) {
    const { url: realtimeUrl } = useRealtimeUrl({
        userName,
        userId,
        room,
    });
    const options = {
        share: true,
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        reconnectInterval: 3000,
        onOpen: onOpen,
    };
    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(realtimeUrl, options);

    // Functions
    /**
     * This is our internal function to send realtime
     * messages. By default it will add all the extra
     * info needed and convert it into a json string in
     * order to be able to send a message.
     * @param {Object} data 
     */
    function _sendMessage(data) {
        sendMessage(JSON.stringify({ room, userId, userName, ...data }));
    }

    return {
        sendMessage: _sendMessage,
        lastMessage,
        readyState,
    };
}
