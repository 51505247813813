import React, { useState } from 'react';
import { Button, Box, TextField, Tooltip } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    newMessageField: {
        width: '80%',
    },
    connectionStatus: {
        padding: '0 5px',
    },
    submitButton: {
        width: '20%',
    },
}));

export default function UserName(props) {

    const {
        connectionStatus,
        sendMessage,
        localUserName,
        setLocalUserName,
    } = props;
    const classes = useStyles();
    const [userName, setUserName] = useState(localUserName);
    const onSubmit = (evt) => {
        evt.preventDefault();
        if(localUserName === userName) return;
        setLocalUserName(userName);
        sendMessage({ action: "setUsername", userName });
    };


    return (
        <form
            onSubmit={onSubmit}
            className={classes.formRoot}
        >
            <Box
                display="flex"
                flexGrow={1}
                width="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
            >
                <TextField
                    className={classes.newMessageField}
                    required
                    autoComplete="off"
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    id="user-name"
                    label="User Name"
                />
                <Button
                    disabled={connectionStatus !== 'Open'}
                    type="submit"
                    className={classes.submitButton}
                    color={connectionStatus === 'Open' ? 'secondary' : 'primary'}
                >
                    Change
                        <Tooltip title={`Socket status: ${connectionStatus}`} aria-label="connection-status">
                        <AccountCircleIcon
                            className={classes.connectionStatus}
                            color={connectionStatus === 'Open' ? 'secondary' : 'primary'}
                        />
                    </Tooltip>
                </Button>
            </Box>
        </form>
    )
}
