import React from 'react';
import { Box } from '@material-ui/core';

export function Presentation(props) {

    const { src } = props;

    return (
        <Box width="100%" height="100%">
            <iframe
                title="Presentation"
                width="100%"
                height="100%"
                src={src}
            />
        </Box>
    )
}
