import React, { useRef } from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import Logger from 'js-logger';
import { 
    MicOff as MicOffIcon,
    VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import { AudioVideo } from '../AudioVideo';

const logger = Logger.get('ConferenceVideo');

export function ConferenceVideo({
    name = 'Guest',
    videoTrack,
    audioTrack,
    isLocalUser,
    isScreenShare,
    isLoading,
    isLarge,
    showName,
    ...props 
}) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    function getMutedIcon() {
        const size = isXs && !isLocalUser && !isLarge ? '0.7rem' : (isXs ? '1rem' : '1.3rem');
        const video = (<VideocamOffIcon style={{ width: size, height: size, marginRight: '3px' }} />);
        const audio = (<MicOffIcon style={{ width: size, height: size, marginRight: '3px' }} />);

        return audioTrack ? video : audio;
    }

    return (
        <Box position="relative" {...props}>
            <Box bgcolor={isScreenShare ? 'rgba(0,0,0,0)' : '#000'} width="100%" pt="56.25%" />
            
            <AudioVideo
                audioTrack={!isLocalUser && audioTrack}
                videoTrack={videoTrack}
                style={{
                    transform: (isLocalUser && 'scale(-1, 1)'),
                    width: '100%',
                    maxHeight: '100%',
                    position: 'absolute',
                    top: 0,
                }}
            />

            {!isLoading && !isScreenShare && (!(videoTrack && audioTrack) || showName) && (
                <Box 
                    color="#fff" 
                    bgcolor="rgba(0,0,0,0.4)"
                    position="absolute" 
                    top="0"
                    bottom={isLarge ? null : '0'}
                    right={isLarge ? null : '0'}
                    left="0"
                    display="flex"
                    justifyContent={videoTrack ? 'flex-start' : 'center'}
                    alignItems={videoTrack ? 'flex-end' : 'center'} 
                >
                    <Box 
                        px={1}
                        textAlign="center"
                    >
                        {showName && !isLarge && (
                            <Box 
                                fontSize={isXs ? '8px' : '12px'} 
                            >
                                {name}
                            </Box>
                        )}

                        <Box 
                            className="test"
                            fontSize={isLarge ? (isXs ? '12px' : '14px') : '10px'} 
                            display="flex" 
                            justifyContent="center" 
                            alignItems="center"
                            pt={!isLocalUser && (showName ? .5 : 1)}
                            pb={isLarge && .5}
                        >
                            {!(videoTrack && audioTrack) && getMutedIcon()}{' '}

                            {showName && isLarge && `${name} `}

                            {(!isXs || isLocalUser) && !audioTrack && (isLarge ? 'is muted' : 'Mic')}{' '}

                            {(!isXs || isLocalUser) && !videoTrack && !audioTrack && 'and '}

                            {(!isXs || isLocalUser) && !videoTrack && 'Camera'} {(!isXs || isLocalUser) && !isLarge && 'off'}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
