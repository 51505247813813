import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import {
    AlbumSharp as RecordingIcon,
} from '@material-ui/icons';


export function RecordingIndicator() {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            position="absolute"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            padding="10px"
        >
            {!isSm && (<Typography color="error" variant="subtitle2">RECORDING</Typography>)}
            &nbsp;
            <RecordingIcon color="error" />
        </Box>
    )
}
