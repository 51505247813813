import React, { useState } from 'react';
import { Button, Box, TextField, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    newMessageField: {
        width: '80%',
    },
    connectionStatus: {
        padding: '0 5px',
    },
    submitButton: {
        width: '20%',
    },
}));


export default function NewMessage(props) {

    const { connectionStatus, sendMessage } = props;
    const classes = useStyles();
    const [content, setContent] = useState('');
    const onSubmit = (evt) => {
        evt.preventDefault();
        sendMessage({ action: 'tempMessage', content, createdAt: new Date().toISOString() });
        setContent('');
    };


    return (
        <form
            onSubmit={onSubmit}
            className={classes.formRoot}
        >
            <Box
                display="flex"
                flexGrow={1}
                width="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
            >
                <TextField
                    className={classes.newMessageField}
                    required
                    autoComplete="off"
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    id="new-message"
                    label="New Message"
                />
                <Button
                    className={classes.submitButton}
                    disabled={connectionStatus !== 'Open'}
                    type="submit"
                    color={connectionStatus === 'Open' ? 'secondary' : 'primary'}
                >
                    Send
                        <Tooltip title={`Socket status: ${connectionStatus}`} aria-label="connection-status">
                        <SendIcon
                            className={classes.connectionStatus}
                            color={connectionStatus === 'Open' ? 'secondary' : 'primary'}
                        />
                    </Tooltip>
                </Button>
            </Box>
        </form>
    )
}



